import React from 'react';
import { StyledContent, StyledFormStack, StyledHeaderStack, StyledTitleStack, styles } from '../UnlockForm/UnlockForm.styles';
import { ButtonV2, TextV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import {
  body4ToSubHeadingOverride,
  button2ToButtonsOverride,
  display4ToHeadingOverride
} from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';

interface AskHostSuccessFormProps {
  onClose: () => void;
}

export const AskHostSuccessForm: React.FC<AskHostSuccessFormProps> = ({ onClose }) => {
  const { t2 } = useTranslation('guestUnlock');
  const { title, subtitle, submitButtonText } = t2('askHostSuccessForm');

  return (
    <StyledContent __css={styles.content}>
      <StyledHeaderStack>
        <StyledTitleStack>
          <GuestSiteTypographyOverride override={display4ToHeadingOverride}>
            <TextV2 typographyVariant="display4">{title}</TextV2>
          </GuestSiteTypographyOverride>
          <GuestSiteTypographyOverride override={body4ToSubHeadingOverride}>
            <TextV2 typographyVariant="body4">{subtitle}</TextV2>
          </GuestSiteTypographyOverride>
        </StyledTitleStack>
      </StyledHeaderStack>
      <StyledFormStack spacing={6} __css={styles.formStack}>
        <GuestSiteTypographyOverride override={button2ToButtonsOverride}>
          <ButtonV2 width={'100%'} intent="primary" onClick={onClose}>
            {submitButtonText}
          </ButtonV2>
        </GuestSiteTypographyOverride>
      </StyledFormStack>
    </StyledContent>
  );
};
