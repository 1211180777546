import { Box } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ActionBar, ActionData } from './components/ActionBar/ActionBar';
import { withWindow } from '@shared/utils/withWindow';
import { InlineEditorWrapper } from './InlineEditor.styles';
import { isInIframe } from '@shared/utils/isInIframe';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useQueryParamHelper } from '@shared/core/queryString';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';
import { ParentMessageListener } from './components/ParentMessageListener/ParentMessageListener';
import { telemetryData } from './InlineEditor.telemetry';
import { AnimatedBorder } from './components/AnimatedBorder/AnimatedBorder';
import { CSSObject } from '@withjoy/joykit/components/Box/Box.types';
import { EventPageType } from '@graphql/generated';
import { useIsStickyElement } from '@shared/hooks/useIsStickyElement';

type InlineEditorProps = {
  elementLabel: string;
  actionData: ActionData;
  wrapperType?: 'actionInside' | 'actionOutside' | 'emptyChild';
  minHeight?: string;
  wrapperCSS?: CSSObject;
  containerCSS?: CSSObject;
  inset?: number | { x: number; y: number };
  componentName: 'pagePhoto' | 'eventDisplayName' | 'eventDate' | 'greetings' | 'eventLocation' | 'welcomePageTheme' | 'page';
  pageName: Maybe<EventPageType>;
  pageSlug: string;
  isEligibleForInlineEditing?: boolean;
  stickyOnScroll?: boolean;
};

export const InlineEditor: FC<InlineEditorProps> = props => {
  const {
    componentName,
    pageName,
    pageSlug,
    elementLabel,
    actionData,
    wrapperType = 'actionOutside',
    minHeight,
    children,
    wrapperCSS = {},
    containerCSS = {},
    inset = -16,
    isEligibleForInlineEditing = true,
    stickyOnScroll = false
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [scaleValue, setScaleValue] = useState(1);
  const [isHovered, setIsHovered] = useState(false);
  const [position, setPosition] = useState<'top' | 'bottom'>('top');

  const mouseInside = useRef(false);

  const queryParamHelper = useQueryParamHelper();
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } }, false);

  const inlineEditingEnabled = useMemo(() => queryParamHelper.getValue('feature.enableInlineEditing') === 'true', [queryParamHelper]);
  const isParentInMobileResolution = useMemo(() => queryParamHelper.getValue('feature.isMobileInlineEditMode') === 'true', [queryParamHelper]);

  const adminDashboardInlineEditingEnabled = inlineEditingEnabled && isEligibleForInlineEditing;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const childWrapperRef = useRef<HTMLDivElement>(null);
  const actionBarRef = useRef<HTMLDivElement>(null);

  const isPreviewing = isInIframe();

  const { isSticky } = useIsStickyElement(`${elementLabel}-observer-mark`, !(stickyOnScroll && !(isMobile && isParentInMobileResolution)));

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const el = e.target;
      const domNode = wrapperRef.current;

      setTimeout(() => {
        //  to handle when there is another inline item as child and that is active
        const isChildItemActive = !!domNode?.querySelector('.inline-editor-wrapper-edit-active');
        if (isChildItemActive) {
          setIsEditing(false);
        }
      }, 500);

      if (!(domNode && el instanceof Node && domNode.contains(el)) && isEditing) {
        if (isEditing) {
          sendMessageToParentWindow({
            action: 'telemetryData',
            value: telemetryData.inlineEditCTAClick(componentName, pageName, pageSlug, 'focusOut'),
            source: 'joyPreview'
          });
        }
        setIsEditing(false);
        // to show the preview button when the inline editor is closed
        sendMessageToParentWindow({ action: 'togglePreviewButton', value: { show: true }, source: 'joyPreview' });
      }
    };
    if (isEditing) {
      withWindow(window => {
        window.document.addEventListener('mousedown', handleClickOutside);
      });
    }
    return () => {
      withWindow(window => {
        window.document.removeEventListener('mousedown', handleClickOutside);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  const handleOnClick = useEventCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    // toggles the edit mode
    setIsEditing(prev => {
      // to show/hide the preview button when the inline editor is opened/closed
      sendMessageToParentWindow({ action: 'togglePreviewButton', value: { show: prev }, source: 'joyPreview' });
      sendMessageToParentWindow({
        action: 'telemetryData',
        value: telemetryData.inlineEditCTAClick(componentName, pageName, pageSlug, prev ? 'focusOut' : 'focusIn'),
        source: 'joyPreview'
      });
      return !prev;
    });
  });

  const applyScaling = useCallback((value: number) => value / scaleValue, [scaleValue]);

  const onActionClick = useEventCallback((action: string) => {
    sendMessageToParentWindow({ action: 'telemetryData', value: telemetryData.inlineActionClicked(componentName, pageName, pageSlug), source: 'joyPreview' });
  });

  const handleOnMouseEnter = useCallback(() => {
    if (!(isMobile && isParentInMobileResolution)) {
      mouseInside.current = true;
      setIsHovered(true);
    }
  }, [isMobile, isParentInMobileResolution]);

  useEffect(() => {
    const handlePosition = () => {
      const rect = actionBarRef.current?.getBoundingClientRect();
      // Check if the element's top is out of the viewport
      if (rect?.top && rect.top < 0) {
        setPosition('bottom');
      }
    };

    // Run on mount and on window resize or scroll
    setTimeout(() => handlePosition(), 300);
  }, []);

  const actionBarTop = useMemo(() => {
    if ((isMobile && isParentInMobileResolution) || position === 'bottom') {
      return 'unset';
    } else if (wrapperType === 'actionOutside') {
      return isSticky ? '0px' : pxToRem(applyScaling(-80));
    } else {
      return '50%';
    }
  }, [applyScaling, isMobile, isParentInMobileResolution, isSticky, position, wrapperType]);

  const handleOnMouseOver = useCallback(() => {
    const domNode = wrapperRef.current;
    setTimeout(() => {
      const isChildItemActive = !!domNode?.querySelector('.inline-edit-hover-active');
      if (isChildItemActive) {
        setIsHovered(false);
      } else if (mouseInside.current) {
        setIsHovered(true);
      }
    }, 200);
  }, []);

  const generatedClassNames = useMemo(() => {
    let classNames = 'inline-editor-wrapper';
    if (isEditing) {
      classNames = `${classNames} inline-editor-wrapper-edit-active`;
    } else {
      classNames = `${classNames} inline-editor-wrapper-edit-inactive`;
    }
    if (isHovered) {
      classNames = `${classNames} inline-edit-hover-active`;
    } else {
      classNames = `${classNames} inline-edit-hover-inactive`;
    }
    return classNames;
  }, [isEditing, isHovered]);

  const insetParser = useCallback(
    (insetValue: number | { x: number; y: number }, offset: number = 0) => {
      if (typeof insetValue === 'number') {
        return { x: pxToRem(applyScaling(insetValue) + offset), y: pxToRem(applyScaling(insetValue) + offset) };
      }
      const xValue = insetValue.x ? pxToRem(applyScaling(insetValue.x) + offset) : '0px';
      const yValue = insetValue.y ? pxToRem(applyScaling(insetValue.y) + offset) : '0px';

      return { x: xValue, y: yValue };
    },
    [applyScaling]
  );

  const insetToCssStyleFormatter = useCallback((insetObj: { x: string; y: string }) => {
    return `${insetObj.y} ${insetObj.x}`;
  }, []);

  return (
    <>
      {adminDashboardInlineEditingEnabled ? (
        wrapperType === 'emptyChild' ? (
          <InlineEditorWrapper className={generatedClassNames} isPreviewing={isPreviewing} position="relative" height={8} __css={wrapperCSS}>
            <ParentMessageListener
              onScaleChange={value => setScaleValue(value)}
              onParentFocus={() => {
                if (isEditing) {
                  sendMessageToParentWindow({
                    action: 'telemetryData',
                    value: telemetryData.inlineEditCTAClick(componentName, pageName, pageSlug, 'focusOut'),
                    source: 'joyPreview'
                  });
                }
                setIsEditing(false);
              }}
            />
            <Box
              ref={childWrapperRef}
              height="100%"
              __css={containerCSS}
              onClick={handleOnClick}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={() => {
                mouseInside.current = false;
                setIsHovered(false);
              }}
              onMouseOver={handleOnMouseOver}
              cursor="pointer"
              display="flex"
              alignItems="center"
            >
              <Box width="100%" position="relative">
                <AnimatedBorder
                  isVisibile={isEditing || isHovered}
                  borderWidth={pxToRem(applyScaling(2))}
                  borderRadius={`${applyScaling(8)}px`}
                  inset={{ x: '0px', y: '0px' }}
                  padding={0}
                  height="1px"
                />
              </Box>

              <Box
                key={`${elementLabel}-action-bar`}
                position={isSticky ? 'fixed' : 'absolute'}
                width="100%"
                zIndex={1300}
                top="50%"
                left={isSticky ? (wrapperRef.current?.getBoundingClientRect().x || 0) + (wrapperRef.current?.getBoundingClientRect().width || 0) / 2 : '50%'}
                transform={'translate(-50%, -50%)'}
                opacity={isEditing || isHovered ? 1 : 0}
                visibility={isEditing || isHovered ? 'visible' : 'hidden'}
                transition=" bottom 0.5s ease-in-out, opacity 0.5s ease-in-out, visibility 0.5s ease-in-out"
                ref={actionBarRef}
                onClick={e => e.stopPropagation()}
              >
                <ActionBar actionData={actionData} actionView={'detailed'} scaleValue={scaleValue} onActionClick={onActionClick} />
              </Box>
            </Box>
          </InlineEditorWrapper>
        ) : (
          <InlineEditorWrapper
            className={generatedClassNames}
            isPreviewing={isPreviewing}
            position="relative"
            ref={wrapperRef}
            _after={isEditing && wrapperType === 'actionInside' ? { content: '""', inset: 0, position: 'absolute', background: 'rgba(255, 255, 255, 0.40)' } : {}}
            _hover={
              !isEditing && isHovered && !(isMobile && isParentInMobileResolution) && wrapperType === 'actionInside'
                ? { _after: { content: '""', position: 'absolute', inset: 0, background: 'rgba(255, 255, 255, 0.20)' } }
                : {}
            }
            _active={
              !isEditing && isHovered && !(isMobile && isParentInMobileResolution) && wrapperType === 'actionInside'
                ? { _after: { content: '""', position: 'absolute', inset: 0, background: 'rgba(255, 255, 255, 0.40)' } }
                : {}
            }
            __css={wrapperCSS}
          >
            {stickyOnScroll && <Box id={`${elementLabel}-observer-mark`} width="100%" position="absolute" top={pxToRem(applyScaling(-80))} />}
            <ParentMessageListener
              onScaleChange={value => setScaleValue(value)}
              onParentFocus={() => {
                if (isEditing) {
                  sendMessageToParentWindow({
                    action: 'telemetryData',
                    value: telemetryData.inlineEditCTAClick(componentName, pageName, pageSlug, 'focusOut'),
                    source: 'joyPreview'
                  });
                }
                setIsEditing(false);
              }}
            />
            <Box
              ref={childWrapperRef}
              minHeight={minHeight || childWrapperRef.current?.firstElementChild?.clientHeight}
              __css={containerCSS}
              _hover={
                !isEditing && isHovered && !(isMobile && isParentInMobileResolution)
                  ? {
                      _after: {
                        position: 'absolute',
                        inset: wrapperType === 'actionOutside' ? insetToCssStyleFormatter(insetParser(inset)) : 0,
                        background: wrapperType === 'actionOutside' ? 'rgba(255, 255, 255, 0.20)' : ''
                      },
                      _before: {
                        content: `"${elementLabel}"`,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        color: 'white',
                        top: wrapperType === 'actionOutside' ? pxToRem(applyScaling(-45)) : pxToRem(applyScaling(16)),
                        left: wrapperType === 'actionOutside' ? insetParser(inset).x : pxToRem(applyScaling(16)),
                        backgroundColor: '#4E06F2',
                        fontFamily: 'Inter UI',
                        fontSize: pxToRem(applyScaling(13)),
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: pxToRem(18.2),
                        letterSpacing: pxToRem(-0.032),
                        borderRadius: pxToRem(applyScaling(8)),
                        paddingX: pxToRem(applyScaling(8)),
                        height: pxToRem(applyScaling(24)),
                        zIndex: 'banner'
                      }
                    }
                  : {}
              }
              _active={
                !isEditing && isHovered && !(isMobile && isParentInMobileResolution)
                  ? {
                      _after: {
                        position: 'absolute',
                        inset: wrapperType === 'actionOutside' ? insetToCssStyleFormatter(insetParser(inset)) : 0,
                        background: wrapperType === 'actionOutside' ? 'rgba(255, 255, 255, 0.40)' : ''
                      },
                      _before: {
                        content: `"${elementLabel}"`,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        color: 'white',
                        top: wrapperType === 'actionOutside' ? pxToRem(applyScaling(-45)) : pxToRem(applyScaling(16)),
                        left: wrapperType === 'actionOutside' ? insetParser(inset).x : pxToRem(applyScaling(16)),
                        backgroundColor: '#4E06F2',
                        fontFamily: 'Inter UI',
                        fontSize: pxToRem(applyScaling(13)),
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: pxToRem(18.2),
                        letterSpacing: pxToRem(-0.032),
                        borderRadius: pxToRem(applyScaling(8)),
                        paddingX: pxToRem(applyScaling(8)),
                        height: pxToRem(applyScaling(24)),
                        zIndex: 'banner'
                      }
                    }
                  : {}
              }
              _after={{ content: '""' }}
              onClick={handleOnClick}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={() => {
                mouseInside.current = false;
                setIsHovered(false);
              }}
              onMouseOver={handleOnMouseOver}
              cursor="pointer"
            >
              {children}
              <AnimatedBorder
                isVisibile={isEditing || isHovered}
                borderWidth={pxToRem(applyScaling(2))}
                borderRadius={`${applyScaling(8)}px`}
                inset={wrapperType === 'actionOutside' ? insetParser(inset) : { x: '0px', y: '0px' }}
                padding={wrapperType === 'actionOutside' ? 0 : pxToRem(applyScaling(8))}
              />
            </Box>
            <Box
              key={`${elementLabel}-action-bar`}
              position={(isMobile && isParentInMobileResolution) || isSticky ? 'fixed' : 'absolute'}
              width="100%"
              zIndex={1300}
              top={actionBarTop}
              left={isSticky ? (wrapperRef.current?.getBoundingClientRect().x || 0) + (wrapperRef.current?.getBoundingClientRect().width || 0) / 2 : '50%'}
              transform={wrapperType === 'actionOutside' || (isMobile && isParentInMobileResolution) ? 'translate(-50%)' : 'translate(-50%, -50%)'}
              bottom={isMobile && isParentInMobileResolution ? (isEditing ? pxToRem(applyScaling(24)) : '-100%') : position === 'bottom' ? pxToRem(applyScaling(-80)) : 'unset'}
              opacity={isEditing ? 1 : 0}
              visibility={isEditing ? 'visible' : 'hidden'}
              transition=" bottom 0.5s ease-in-out, opacity 0.5s ease-in-out, visibility 0.5s ease-in-out"
              ref={actionBarRef}
              onClick={e => e.stopPropagation()}
            >
              <ActionBar actionData={actionData} actionView={wrapperType === 'actionOutside' ? 'compact' : 'detailed'} scaleValue={scaleValue} onActionClick={onActionClick} />
            </Box>
          </InlineEditorWrapper>
        )
      ) : (
        <>{children}</>
      )}
    </>
  );
};

InlineEditor.displayName = 'InlineEditor';
