import { useMemo } from 'react';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useCustomizationContext } from './useCardCustomization';
import { useNavigationContext } from './useNavigationState';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { useSalesConfig } from './useSalesConfig';

export interface PromotionConfig {
  code: string;
  description?: string;
  categoryList: string[];
  expirationDate?: string;
  discountPercentage: number;
  active: boolean;
  usesPerEvent?: number;
}

const isCodeExpired = (promotion: PromotionConfig) => {
  const { active, expirationDate: expiration } = promotion;

  if (!active) {
    return true;
  }

  if (!expiration) {
    // If no expiration date, code never expires, so it is valid.
    return false;
  } else {
    const expirationDate = new Date(expiration);
    const currentDate = Date.now();
    if (Number.isNaN(expirationDate.valueOf())) {
      // If code is not a valid date, fail-safe and validate anyways.
      return false;
    } else {
      return expirationDate.valueOf() < currentDate;
    }
  }
};

export type PromotionVendor = 'paperlust' | 'taylor' | 'joy_digital';
export type ProductInfo = { occasion: string; category: StationeryTemplateCategoryEnum; vendor?: PromotionVendor };

export const doesProductComponentMatch = (value: string, acceptableValue: string) => {
  return value === acceptableValue || value === '*';
};

export const isPromoValidForVendor = (promotion: PromotionConfig, vendor: PromotionVendor) => {
  const products = promotion.categoryList;
  return products.some(product => {
    if (!product) {
      return false;
    }

    const productTree = product.split('/');
    if (productTree.length !== 3) {
      // Strict on structure. Must be occasion/category/vendor.
      // Use wildcards to do partial matching.
      return false;
    }

    const [_, __, promoVendor] = productTree;
    return doesProductComponentMatch(promoVendor, vendor);
  });
};

// Checks if promption applies to the current product
const isPromoValidForProduct = (promotion: PromotionConfig, currentProductInfo: ProductInfo) => {
  const products = promotion.categoryList;
  return products.some(product => {
    if (!product) {
      return false;
    }
    const productTree = product.split('/');
    if (productTree.length !== 3) {
      // Strict on structure. Must be occasion/category/vendor.
      // Use wildcards to do partial matching.
      return false;
    }
    const [occasion, category] = productTree;
    const { occasion: currentOccasion, category: currentCategory, vendor: currentVendor } = currentProductInfo;

    if (!doesProductComponentMatch(occasion, currentOccasion)) {
      return false;
    }

    if (!doesProductComponentMatch(category, currentCategory)) {
      return false;
    }

    if (currentVendor && !isPromoValidForVendor(promotion, currentVendor)) {
      return false;
    }

    return true;
  });
};

export const isPromoValid = (promotion: PromotionConfig | undefined, productInfo: ProductInfo): boolean => {
  if (!promotion) {
    return false;
  }

  if (isCodeExpired(promotion)) {
    return false;
  }

  if (!isPromoValidForProduct(promotion, productInfo)) {
    return false;
  }

  return true;
};

// Just gets all promotions.
// Not restricted to Taylor only contexts.
export const usePromotionList = (category: StationeryTemplateCategoryEnum) => {
  const { value, payload } = useFeatureValue('printCouponCodeLaunchV2');
  const { sales, isEnabled: salesEnabled } = useSalesConfig(category);

  const coupons: PromotionConfig[] | null = payload instanceof Array ? payload : null;
  const couponsAndSales = useMemo(() => {
    return [...(sales && salesEnabled ? sales : []), ...(coupons ? coupons : [])];
  }, [coupons, sales, salesEnabled]);

  return {
    isEnabled: value === 'on',
    promotions: couponsAndSales
  };
};

// Gets current promotion from CardCustomizer saved promo and validates
export const usePromotionConfig = (vendor?: PromotionVendor) => {
  const { value, payload } = useFeatureValue('printCouponCodeLaunchV2');
  const [savedPromoCode] = useCustomizationContext(draft => draft.config.customizations.promoCode);
  const [category] = useCustomizationContext(draft => draft.stationeryTemplateCategory);
  const { sales, isEnabled: salesEnabled } = useSalesConfig(category);
  const { currentStep } = useNavigationContext();

  const coupons: PromotionConfig[] | null = payload instanceof Array ? payload : null;
  const couponsAndSales = useMemo(() => {
    return [...(sales && salesEnabled ? sales : []), ...(coupons ? coupons : [])];
  }, [coupons, sales, salesEnabled]);

  const currentPromoConfig = useMemo(() => {
    if (typeof savedPromoCode !== 'string') {
      return;
    }

    // Ensure that the promo code is still valid
    // TODO: Add occasion checking once we add further support for occasions.
    // Currently hardcoding occasion as we do not have a standardized way of fetching it.
    // And in the card customzier, it is not even tracked.
    const product = { occasion: 'wedding', category, vendor };
    return couponsAndSales?.find(promo => isPromoValid(promo, product) && promo.code.toLowerCase() === savedPromoCode.toLowerCase());
  }, [category, couponsAndSales, savedPromoCode, vendor]);

  return {
    isEnabled: value === 'on' && currentStep === 'review',
    promotions: couponsAndSales,
    /**
     * The promotion config for the currently stored promo code.
     */
    currentPromotion: currentPromoConfig
  };
};
