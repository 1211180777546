import React from 'react';
import { Flex, TextV2 } from '@withjoy/joykit';
import PriceSummary from '@apps/card/components/PriceSummary';
import { StationeryPrintOrderDetailsFragment } from '@graphql/generated';
import ReceiptBlock from '@apps/card/components/Blocks/ReceiptBlock';
import { templateToTitle } from '@apps/card/Card.utils';
import { useTranslation } from '@shared/core';
import { ThemeJson } from '@apps/card/routes/CardCustomizer';
import useEnvelopePreview from '@apps/card/hooks/useEnvelopePreview';
import { BLANK_ENVELOPE_FOREGROUND, DraftPreview } from '@apps/card/components/DraftPreview';

// const Block = ({ children, title }: { title: string; children: React.ReactNode }) => {
//   return (
//     <Flex flexDirection="column" rowGap={5}>
//       <TextV2 typographyVariant="hed1">{title}</TextV2>
//       {children}
//     </Flex>
//   );
// };

/**
 * TODO:
 *  - Need some way to get payment method
 */
export const PaymentSummary = ({ order }: { order: StationeryPrintOrderDetailsFragment }) => {
  const envelopePreview = useEnvelopePreview({ order });
  const { t } = useTranslation('stationery');
  const categoryName = t('stationeryTemplateCategoryToTile', order.cardDraft!.stationeryTemplate.category)?.();

  const cardJSON = order.cardDraftJSONBlob as ThemeJson;
  const { taxInMinorUnits, discount, cardDraft } = order;

  return (
    <Flex width={'100%'} maxWidth="100%" alignSelf="center" paddingX={{ _: 6, md: 0 }} flexDirection={{ _: 'column', md: 'row' }} rowGap={10} columnGap={140}>
      <Flex display={{ _: 'none', md: 'flex' }} flexDirection="column" flex={1} gap={10} maxWidth="400px">
        <Flex flexDirection="column" gap={5} alignItems="center">
          <TextV2 typographyVariant="label2" color="mono8">
            Card Back
          </TextV2>
          <DraftPreview images={{ cardBack: order.printCardBackPreviewUrl }} diecut={cardJSON.customizations.shape} />
        </Flex>
        {envelopePreview.isPreviewAvailable && (
          <>
            <Flex flexDirection="column" gap={5} alignItems="center">
              <TextV2 typographyVariant="label2" color="mono8">
                Envelope Front
              </TextV2>
              <DraftPreview images={{ envelopFront: envelopePreview.frontImageUrl ?? BLANK_ENVELOPE_FOREGROUND }} />
            </Flex>
            <Flex flexDirection="column" gap={5} alignItems="center">
              <TextV2 typographyVariant="label2" color="mono8">
                Envelope Back
              </TextV2>
              <DraftPreview images={{ envelopeBack: envelopePreview.backImageUrl ?? BLANK_ENVELOPE_FOREGROUND }} />
            </Flex>
          </>
        )}
      </Flex>
      <Flex flexDirection="column" flex={1}>
        <PriceSummary
          promotion={discount}
          salesTax={taxInMinorUnits}
          templateCategory={cardDraft!.stationeryTemplate.category}
          cardConfig={cardJSON}
          priceUpTo="total"
          flex={1}
          maxWidth={{ md: '440px' }}
          width="100%"
          alignSelf="flex-end"
        >
          {summary => (
            <>
              <TextV2 typographyVariant="hed3">Order Summary</TextV2>
              <ReceiptBlock title={categoryName} subTitle={templateToTitle(order.cardDraft!.stationeryTemplate)} lines={summary.card} />
              <ReceiptBlock title="Back" lines={summary.back} />
              <ReceiptBlock title="Envelope" lines={summary.envelope} />
              <ReceiptBlock title="Shipping Method" lines={summary.shipping} />
              {!!taxInMinorUnits && <ReceiptBlock title="Taxes" lines={summary.tax} />}
            </>
          )}
        </PriceSummary>
      </Flex>
    </Flex>
  );
};
