import React, { useCallback, useMemo } from 'react';
import { OverlayView, useGoogleMap } from '@react-google-maps/api';
import { CARD_DIMENSIONS_SAFE, StyledMapPinCard, StyledMapPinLabel, StyledMapPinLabelContainer, StyledMapPinLabelTip, StyledMapPinLabelTipShadow, styles } from './MapPin.styles';
import { createPortal } from 'react-dom';
import { BoxProps, StyleSystemProps } from '@withjoy/joykit';

export type MapPinProps = Merge<
  React.DOMAttributes<HTMLElement> & BoxProps,
  {
    showOnTop?: boolean;
    isActive?: boolean;
    label: string | React.ReactNode;
    position: { lat: number; lng: number };
    isCardVisible?: boolean;
  }
>;

export const MapPin: React.FC<MapPinProps> = ({ showOnTop, children, position, isActive, isCardVisible, label, ...props }) => {
  const isClickable = typeof props.onClick === 'function';

  return (
    <OverlayView mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} position={position}>
      <>
        {children || null}
        <StyledMapPinLabelContainer role="group" __css={styles.joyMapPinLabelContainer({ isActive, isClickable, showOnTop, isCardVisible })} {...props}>
          <StyledMapPinLabelTipShadow __css={styles.joyMapPinLabelTipShadow} />
          <StyledMapPinLabel __css={styles.joyMapPinLabel({ isActive, isClickable, isCardVisible })}>{label}</StyledMapPinLabel>
          <StyledMapPinLabelTip __css={styles.joyMapPinLabelTip({ isActive, isClickable, isCardVisible })} />
        </StyledMapPinLabelContainer>
      </>
    </OverlayView>
  );
};

export const CircleMapPin: React.FC<MapPinProps & { isDestination?: boolean }> = ({ showOnTop, children, position, isActive, isCardVisible, label, isDestination, ...props }) => {
  const isClickable = typeof props.onClick === 'function';

  return (
    <OverlayView mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} position={position}>
      <>
        {children || null}
        <StyledMapPinLabelContainer role="group" __css={styles.joyMapPinLabelContainer({ isActive, isClickable, showOnTop, isCardVisible })} {...props}>
          <StyledMapPinLabel __css={styles.joyCircleMapPinLabel({ isActive, isClickable, isCardVisible, isDestination })}>{label}</StyledMapPinLabel>
        </StyledMapPinLabelContainer>
      </>
    </OverlayView>
  );
};

export const MAP_CARDS_PORTAL_ID = 'hotel-selection-map-cards-portal';

export const MAP_CARD_POSITIONS = {
  TOP_LEFT: 'top-left',
  TOP_CENTER: 'top-center',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_RIGHT: 'bottom-right'
} as const;

export const MapPinWithCard: React.FC<
  MapPinProps & {
    /**
     * If true, the card will be rendered in a portal. This is useful when the card is not positioned on top of the marker.
     * 'portalContainer' must be provided when 'usePortal' is true.
     */
    usePortal?: boolean;
    portalContainer?: HTMLElement;
    cardStyleOverride?: StyleSystemProps;
    /**
     * Prop to force a re-render of the component. This is useful when the card is not re-rendered when the position changes.
     */
    forceUpdate?: string;
  }
> = ({ children, usePortal, portalContainer, cardStyleOverride, forceUpdate, ...props }) => {
  const googleMap = useGoogleMap();

  const cardPosition = useMemo(() => {
    if (usePortal) return;

    // Get the bounds and projection to display the card to always be visible within the map
    const bounds = googleMap?.getBounds();
    const projection = googleMap?.getProjection();

    if (!bounds || !projection) return;

    // Get the container dimensions
    const mapDiv = googleMap?.getDiv();

    if (!mapDiv) return;

    const mapWidth = mapDiv?.offsetWidth;
    const mapHeight = mapDiv?.offsetHeight;

    // LatLng to point
    const markerPoint = projection.fromLatLngToPoint(props.position);

    if (!markerPoint) return;

    // Convert bounds to pixel coordinates
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const nePoint = projection.fromLatLngToPoint(ne);
    const swPoint = projection.fromLatLngToPoint(sw);

    if (!nePoint || !swPoint) return;

    // Calculate scale
    const scale = Math.pow(2, googleMap?.getZoom() || 0);

    // Calculate the marker's pixel coordinates relative to the container
    const markerPixel = {
      x: (markerPoint.x - swPoint.x) * scale,
      y: (markerPoint.y - nePoint.y) * scale
    };

    // Distances from each border
    const distances = {
      top: markerPixel.y,
      left: markerPixel.x,
      bottom: mapHeight - markerPixel.y,
      right: mapWidth - markerPixel.x
    };

    let position;
    if (distances.top >= CARD_DIMENSIONS_SAFE.height && distances.left >= CARD_DIMENSIONS_SAFE.width / 2 && distances.right >= CARD_DIMENSIONS_SAFE.width / 2) {
      position = MAP_CARD_POSITIONS.TOP_CENTER;
    } else if (distances.bottom >= CARD_DIMENSIONS_SAFE.height && distances.left >= CARD_DIMENSIONS_SAFE.width / 2 && distances.right >= CARD_DIMENSIONS_SAFE.width / 2) {
      position = MAP_CARD_POSITIONS.BOTTOM_CENTER;
    } else if (distances.top >= CARD_DIMENSIONS_SAFE.height && distances.right >= CARD_DIMENSIONS_SAFE.width) {
      position = MAP_CARD_POSITIONS.TOP_RIGHT;
    } else if (distances.bottom >= CARD_DIMENSIONS_SAFE.height && distances.right >= CARD_DIMENSIONS_SAFE.width) {
      position = MAP_CARD_POSITIONS.BOTTOM_RIGHT;
    } else if (distances.top >= CARD_DIMENSIONS_SAFE.height && distances.left >= CARD_DIMENSIONS_SAFE.width) {
      position = MAP_CARD_POSITIONS.TOP_LEFT;
    } else if (distances.bottom >= CARD_DIMENSIONS_SAFE.height && distances.left >= CARD_DIMENSIONS_SAFE.width) {
      position = MAP_CARD_POSITIONS.BOTTOM_LEFT;
    } else {
      // Default to TOP_CENTER if no sufficient space found
      position = MAP_CARD_POSITIONS.TOP_CENTER;
    }

    return position;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate]);

  const renderCard = useCallback(() => {
    return (
      <StyledMapPinCard __css={styles.joyMapPinCard({ isCardVisible: props.isCardVisible, cardStyleOverride, usePortal, cardPosition })} isCardVisible={props.isCardVisible}>
        {children}
      </StyledMapPinCard>
    );
  }, [props.isCardVisible, cardStyleOverride, usePortal, cardPosition, children]);

  return <MapPin {...props}>{usePortal && portalContainer ? createPortal(renderCard(), portalContainer) : renderCard()}</MapPin>;
};
