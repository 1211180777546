import { css } from '@withjoy/joykit';

export const boxShadowValues = {
  drop: {
    xs: `0px 1px 5px -1px rgba(44, 41, 37, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.3)`,
    sm: `0px 4px 12px -2px rgba(44, 41, 37, 0.25), 0px 3px 7px -3px rgba(0, 0, 0, 0.3)`,
    md: `0px 10px 27px -5px rgba(44, 41, 37, 0.25), 0px 7px 16px -8px rgba(0, 0, 0, 0.3)`,
    lg: `0px 30px 60px -12px rgba(44, 41, 37, 0.25), 0px 18px 36px -18px rgba(0, 0, 0, 0.3)`,
    xl: `0px 20px 30px -10px #2C2925, 0px 30px 60px -30px rgba(0, 0, 0, 0.3)`,
    xxl: `0px 50px 100px -20px rgba(44, 41, 37, 0.25), 0px 30px 60px -30px rgba(0, 0, 0, 0.3)`
  }
};

export const dropshadows = {
  large: css`
    box-shadow: ${boxShadowValues.drop.lg};
  `
};

export const boxShadowValuesV2 = {
  hardxs: '0px 1px 5px -1px rgba(45, 41, 37, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.30)',
  md: '0px 10px 27px -5px rgba(45, 41, 37, 0.25), 0px 7px 16px -8px rgba(0, 0, 0, 0.30)',
  xxxl: '0px 3px 8px 0px rgba(0, 0, 0, 0.03), 0px 10px 60px -20px rgba(45, 41, 37, 0.03), 0px 9px 36px -30px rgba(0, 0, 0, 0.04)'
};
