import React, { useMemo, useState } from 'react';

import { format, parseISO } from 'date-fns';
import { Box, ButtonV2, Flex } from '@withjoy/joykit';
import OrderInfo from '@apps/card/components/Blocks/OrderInfo';
import { StationeryPrintOrderDetailsFragment, useCreateStationeryDraftFromPrintOrderMutation } from '@graphql/generated';
import { ThemeJson } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { templateToTitle } from '@apps/card/Card.utils';
import { animationTransition } from '@shared/utils/animationTransition';
import { pxToRem } from '@withjoy/joykit/theme';
import { useHistory, useTranslation } from '@shared/core';
import { useEventInfo } from '@shared/utils/eventInfo';
import { withWindow } from '@shared/utils/withWindow';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { DraftPreview } from '../DraftPreview';
import { DigitalPaymentSummary } from './components/DigitalPaymentSummary/DigitalPaymentSummary';
import { usePremiumDigitalJobDetailsTelemetryProxyContext } from '../PremiumDigitalJobDetailsTelemetryProxyProvider/PremiumDigitalJobDetailsTelemetryProxyProvider';
import EditCardCountdown from './components/EditCardCountdown';

interface DigitalJobDetailsProps {
  order: StationeryPrintOrderDetailsFragment;
  variant: 'overview' | 'detailed';
}

export const DigitalJobDetails = (props: DigitalJobDetailsProps) => {
  const { order, variant } = props;
  const telemetry = usePremiumDigitalJobDetailsTelemetryProxyContext();
  const history = useHistory();
  const { eventHandle } = useEventInfo();
  const { getDigitalCustomizeDraftPath, goToCustomizeDraft, getOrderDetailsPath, goToCustomizeDigitalOrderDetails } = useCardsRouterContext();
  const [createDraftFromOrder, { loading: isCreatingDraftFromOrder }] = useCreateStationeryDraftFromPrintOrderMutation();

  const { t } = useTranslation('stationery');
  const tDigitalOrder = t('dashboard', 'ordersList', 'digitalOrder');
  const [previewImage, setPreviewImage] = useState(0);
  const orderNumber = useMemo(() => order.orderNumber, [order.orderNumber]);
  const orderPlaced = useMemo(() => order.printPayment?.paidAt, [order.printPayment?.paidAt]);

  const metadata = useMemo(() => {
    const category = order.cardDraft?.stationeryTemplate.category;
    const line1 = category ? tDigitalOrder.orderType[category]() : '';
    const line2 = templateToTitle({ name: order.cardDraft?.stationeryTemplate.name, themeId: order.cardDraft?.stationeryTemplate.themeId ?? '' });
    const line3 = `${tDigitalOrder.orderPlaced()}: ${format(parseISO(orderPlaced), 'PPP')}`;

    return `${line1}\n${line2}\n${line3}`;
  }, [order.cardDraft?.stationeryTemplate.category, order.cardDraft?.stationeryTemplate.name, order.cardDraft?.stationeryTemplate.themeId, orderPlaced, tDigitalOrder]);

  const alreadySentDigitalCard = order.currentStatus === 'sent';

  const previewImages = [order.printCardFrontPreviewUrl, order.printCardBackPreviewUrl];
  const cardJSON = order.cardDraftJSONBlob as ThemeJson;

  const handleTrackOrder = () => {
    telemetry?.onTrackDeliveryClick();
    withWindow(() => {
      window.location.pathname = `${eventHandle}/edit/guests`;
    });
  };

  const handleSendMore = () => {
    if (order.cardDraft?.id) {
      telemetry?.onSendMoreOrResendClick();
      history.push(`${getDigitalCustomizeDraftPath(order.cardDraft.id)}?step=postCheckout&orderNumber=${orderNumber}`);
    }
  };

  const handleOnSelectRecipientsAndSend = () => {
    if (order.cardDraft?.id) {
      telemetry?.onSelectRecipientsAndSendClick();
      history.push(`${getDigitalCustomizeDraftPath(order.cardDraft.id)}?step=postCheckout&orderNumber=${orderNumber}`);
    }
  };

  const handleOrderMatchingPaperCard = async () => {
    telemetry?.onOrderMatchingPaperCardClick();
    const { data } = await createDraftFromOrder({
      variables: {
        data: {
          orderId: props.order.id
        }
      }
    });

    const draftId = data?.createStationeryDraftFromPrintOrder?.id;

    if (draftId) {
      goToCustomizeDraft(draftId);
    }
  };

  const handleOrderDetails = () => {
    telemetry?.onGoToOrderDetailsClick();
    history.push(getOrderDetailsPath(orderNumber));
  };

  return (
    <Flex flexDirection="column" rowGap={10}>
      <Flex columnGap={{ md: 10 }} flexDirection={{ _: 'column', md: 'row' }} rowGap={10}>
        <Flex flexDirection="column" rowGap={8} flex={1} maxWidth={['100%', null, null, '387px']}>
          <Flex flexDirection="column" gap={5} paddingX={{ _: 6, md: 0 }} width="100%" justifyContent="center">
            {previewImage === 0 && <DraftPreview images={{ cardFront: previewImages[0]! }} diecut={cardJSON.customizations.shape} />}
            {previewImage === 1 && <DraftPreview images={{ cardBack: previewImages[1]! }} diecut={cardJSON.customizations.shape} />}
            <Flex justifyContent="center" gap={1} display={{ _: 'flex', md: 'none' }}>
              {previewImages
                .filter(p => p !== undefined)
                .map((url, index) => (
                  <Flex
                    _after={{
                      position: 'absolute',
                      content: '" "',
                      width: '100%',
                      bottom: -12,
                      backgroundColor: 'mono10',
                      borderRadius: 2,
                      height: '2px',
                      transition: animationTransition('opacity'),
                      opacity: index === previewImage ? 1 : 0
                    }}
                    position="relative"
                    cursor="pointer"
                    onClick={() => setPreviewImage(index)}
                    zIndex={50}
                    width={pxToRem(52)}
                    height={pxToRem(52)}
                    key={index}
                    padding={1}
                    justifyContent="center"
                    alignItems="center"
                    backgroundColor="mono2"
                  >
                    <Box as="img" src={url!} alt="Card Preview" width="100%" height="100%" objectFit="contain" objectPosition="center" />
                  </Flex>
                ))}
            </Flex>
          </Flex>
        </Flex>

        <Flex flexDirection="column" paddingY={{ md: 8 }} paddingX={{ _: 6, md: 0 }} flex={1} rowGap={10} justifyContent={{ md: 'center' }}>
          <Flex columnGap={10} flexDirection="column" rowGap={10} width={{ _: '100%', sm2: 400, md: 'unset' }} alignSelf={{ _: 'center', md: 'unset' }}>
            <Flex flexDirection={{ _: 'column', md: 'row' }} alignItems={{ md: 'center' }} gap={10} width="100%" justifyContent="space-between">
              <OrderInfo iconVariant="shop" title={`${tDigitalOrder.orderNumber()}: ${orderNumber}`} description={metadata} />
              <Flex flexDirection="column" gap={5}>
                {alreadySentDigitalCard ? (
                  <>
                    <ButtonV2 width={{ md: 250 }} flex="none" onClick={handleTrackOrder} intent="neutral" shape="rounded">
                      {tDigitalOrder.trackDelivery()}
                    </ButtonV2>
                    <ButtonV2 width={{ md: 250 }} flex="none" onClick={handleSendMore} intent="neutral" shape="rounded" variant="outline">
                      {tDigitalOrder.sendMore()}
                    </ButtonV2>
                    {variant === 'overview' ? (
                      <ButtonV2 width={{ md: 250 }} flex="none" onClick={handleOrderDetails} intent="neutral" shape="rounded" variant="outline">
                        Order Details
                      </ButtonV2>
                    ) : (
                      <ButtonV2
                        width={{ md: 250 }}
                        flex="none"
                        onClick={handleOrderMatchingPaperCard}
                        loading={isCreatingDraftFromOrder}
                        intent="neutral"
                        shape="rounded"
                        variant="outline"
                      >
                        {tDigitalOrder.orderMatchingPaperCard()}
                      </ButtonV2>
                    )}
                  </>
                ) : (
                  <>
                    <ButtonV2 width={{ md: 250 }} flex="none" onClick={handleOnSelectRecipientsAndSend} intent="neutral" shape="rounded">
                      {tDigitalOrder.selectRecipients()}
                    </ButtonV2>
                    {variant === 'overview' && (
                      <ButtonV2 width={{ md: 250 }} flex="none" onClick={handleOrderDetails} intent="neutral" shape="rounded" variant="outline">
                        Order Details
                      </ButtonV2>
                    )}
                    <ButtonV2
                      width={{ md: 250 }}
                      flex="none"
                      onClick={handleOrderMatchingPaperCard}
                      loading={isCreatingDraftFromOrder}
                      intent="neutral"
                      shape="rounded"
                      variant="outline"
                    >
                      {tDigitalOrder.orderMatchingPaperCard()}
                    </ButtonV2>
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
          <EditCardCountdown
            editingEndsAt={null}
            isOrderPaused
            isDigital
            onEditClick={() => {
              telemetry?.onEditOrderClick();
              goToCustomizeDigitalOrderDetails(order.orderNumber);
            }}
          />
        </Flex>
      </Flex>
      {variant === 'detailed' && <DigitalPaymentSummary order={order} />}
    </Flex>
  );
};
