import { Box, Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { CONTENT_BLOCK_MAX_WIDTH, CONTENT_BLOCK_PADDING__X } from '../../LayoutAloha.theme';
import { StyledTitle } from './Page.styles';
import { PrettyLink } from '@apps/guest/packages/layout-engine/components/PrettyLink';
import { ResponsiveText } from '@apps/guest/packages/layout-engine/components/ResponsiveText';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { body3ToSubHeadingOverride, display4ToHeadingOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { Category } from '@graphql/generated';
interface Props
  extends Readonly<{
    title?: Maybe<string>;
    subtitle?: Maybe<string>;
    graphicAccent?: Maybe<JSX.Element>;
    marginTop?: string | string[];
    isAccommodations?: boolean;
    pageSlug?: string;
  }> {}

export const Page = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>((props, pageRef) => {
  const { title, subtitle, graphicAccent, children, isAccommodations, pageSlug, ...restProps } = props;
  return isAccommodations ? (
    <Flex ref={pageRef} {...restProps} maxWidth={'500px'} width="100%" justifyContent={'center'} flexDirection={'column'}>
      {graphicAccent}
      {title && (
        <GuestSiteTypographyOverride override={display4ToHeadingOverride}>
          <ResponsiveText
            typographyVariant={'display4'}
            lineHeight={'62.4px'}
            marginTop={5}
            textAlign={'center'}
            wordBreak="break-word"
            text={title}
            skipResize={'Accommodations' !== title}
            fontCategory={Category.HEADING}
          />
        </GuestSiteTypographyOverride>
      )}
      {subtitle ? (
        <GuestSiteTypographyOverride override={body3ToSubHeadingOverride}>
          <TextV2
            as="div"
            typographyVariant={'body3'}
            lineHeight={'1.75'}
            letterSpacing={'0.16px'}
            fontWeight={325}
            marginY={8}
            marginX={'auto'}
            textAlign={'center'}
            maxWidth={'500px'}
            wordBreak="break-word"
            color={'mono14'}
          >
            <PrettyLink source={pageSlug}>{subtitle}</PrettyLink>
          </TextV2>
        </GuestSiteTypographyOverride>
      ) : null}
      {children}
    </Flex>
  ) : (
    <Box ref={pageRef} {...restProps} maxWidth={{ _: '700px', lg: '614px' }} marginX={'auto'}>
      {graphicAccent}
      {title ? (
        <GuestSiteTypographyOverride override={display4ToHeadingOverride}>
          <StyledTitle typographyVariant={'display4'} marginTop={5} textAlign={'center'}>
            {title}
          </StyledTitle>
        </GuestSiteTypographyOverride>
      ) : null}
      {subtitle ? (
        <GuestSiteTypographyOverride override={body3ToSubHeadingOverride}>
          <TextV2
            as="div"
            typographyVariant={'body3'}
            marginTop={5}
            marginX={'auto'}
            textAlign={'center'}
            maxWidth={CONTENT_BLOCK_MAX_WIDTH}
            paddingX={CONTENT_BLOCK_PADDING__X}
            wordBreak="break-word"
          >
            <PrettyLink source={pageSlug}>{subtitle}</PrettyLink>
          </TextV2>
        </GuestSiteTypographyOverride>
      ) : null}
      {children}
    </Box>
  );
});
