import React from 'react';
import { FormControl, InputV2, ButtonV2, TextV2 } from '@withjoy/joykit';
import { useFindInviteFormController } from './FindInviteForm.controller';
import { StyledContent, StyledFormStack, StyledHeaderStack, StyledInputsStack, StyledTitleStack, styles } from '../UnlockForm/UnlockForm.styles';
import { GuestTypes } from '@apps/guest/packages/layout-engine/components/UnlockDialog/UnlockDialog';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import {
  body4ToSubHeadingOverride,
  button2ToButtonsOverride,
  display4ToHeadingOverride
} from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';

export interface FindInviteFormProps {
  eventId: string;
  setGuestsFound: (p: number) => void;
  setGuest: (p: GuestTypes) => void;
}

export const FindInviteForm: React.FC<FindInviteFormProps> = ({ eventId, setGuestsFound, setGuest }) => {
  const {
    formik,
    loading,
    title,
    subtitle,
    firstNameLabel,
    lastNameLabel,
    firstNamePlaceholder,
    lastNamePlaceholder,
    submitButtonText,
    lastNameInputError,
    firstNameInputError
  } = useFindInviteFormController({ eventId, setGuestsFound, setGuest });

  return (
    <StyledContent __css={styles.content} data-testid="find-invite-form">
      <StyledHeaderStack>
        <StyledTitleStack>
          <GuestSiteTypographyOverride override={display4ToHeadingOverride}>
            <TextV2 typographyVariant="display4">{title}</TextV2>
          </GuestSiteTypographyOverride>
          <GuestSiteTypographyOverride override={body4ToSubHeadingOverride}>
            <TextV2 typographyVariant="body4">{subtitle}</TextV2>
          </GuestSiteTypographyOverride>
        </StyledTitleStack>
      </StyledHeaderStack>
      <StyledFormStack spacing={6} __css={styles.formStack}>
        <form onSubmit={formik.handleSubmit}>
          <StyledInputsStack>
            <FormControl label={firstNameLabel}>
              <InputV2
                placeholder={firstNamePlaceholder}
                isInvalid={(firstNameInputError && formik.touched.firstName) || undefined}
                enableFocusOnMount
                isDisabled={loading}
                {...formik.getFieldProps('firstName')}
              />
            </FormControl>
            <FormControl label={lastNameLabel}>
              <InputV2
                placeholder={lastNamePlaceholder}
                isInvalid={(lastNameInputError && formik.touched.lastName) || undefined}
                isDisabled={loading}
                {...formik.getFieldProps('lastName')}
              />
            </FormControl>
          </StyledInputsStack>
          <GuestSiteTypographyOverride override={button2ToButtonsOverride}>
            <ButtonV2 width={'100%'} intent="primary" type="submit" loading={loading}>
              {submitButtonText}
            </ButtonV2>
          </GuestSiteTypographyOverride>
        </form>
      </StyledFormStack>
    </StyledContent>
  );
};
