import React, { FC, useCallback } from 'react';
import { Flex, TextV2, TooltipV2 } from '@withjoy/joykit';
import { Edit, LockFilled, Moon, PaintBucket, Photos, Sun, Fonts } from '@withjoy/joykit/icons';
import { pxToRem } from '@withjoy/joykit/theme';
import { useTranslation } from '@shared/core';
import { EventPageVisibility } from '@graphql/generated';
import { useFeatureValue } from '@shared/core/featureFlags';

export type ActionData = Partial<
  {
    [key in InlineEditorActions]: key extends InlineEditorActions.VISIBILITY ? { visibility: EventPageVisibility; function: () => void } : () => void;
  }
>;

export enum InlineEditorActions {
  EDIT = 'edit',
  FONT = 'font',
  VISIBILITY = 'visibility',
  EDIT_PHOTO = 'editPhoto',
  EDIT_THEME_COLOR = 'editThemeColor',
  ADD_PHOTO = 'addPhoto'
}

type ActionBarProps = {
  actionData: ActionData;
  actionView?: 'compact' | 'detailed';
  scaleValue: number;
  onActionClick: (action: InlineEditorActions) => void;
};

export const PAGE_VISIBILITY_ICON_MAPPER = {
  [EventPageVisibility.public]: Sun,
  [EventPageVisibility.passwordProtected]: LockFilled,
  [EventPageVisibility.hidden]: Moon
};

const ACTION_TO_ICON_MAPPER = (visibility?: EventPageVisibility) => ({
  [InlineEditorActions.EDIT]: Edit,
  [InlineEditorActions.FONT]: Fonts,
  [InlineEditorActions.VISIBILITY]: PAGE_VISIBILITY_ICON_MAPPER[visibility || EventPageVisibility.public],
  [InlineEditorActions.EDIT_PHOTO]: Photos,
  [InlineEditorActions.EDIT_THEME_COLOR]: PaintBucket,
  [InlineEditorActions.ADD_PHOTO]: Photos
});

export const ActionBar: FC<ActionBarProps> = props => {
  const { actionData, actionView = 'compact', scaleValue, onActionClick } = props;

  const { t2 } = useTranslation('joykit');
  const editorTrans = t2('inlineEditor');

  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';

  const labelTexts = useCallback(
    (visibility?: EventPageVisibility) => {
      const visbiblityTexts = {
        [EventPageVisibility.public]: editorTrans.pagePublic,
        [EventPageVisibility.passwordProtected]: editorTrans.pageProtected,
        [EventPageVisibility.hidden]: editorTrans.pageHidden
      };
      return {
        [InlineEditorActions.EDIT]: editorTrans.content,
        [InlineEditorActions.FONT]: editorTrans.fontStyles,
        [InlineEditorActions.VISIBILITY]: visbiblityTexts[visibility || EventPageVisibility.public],
        [InlineEditorActions.EDIT_PHOTO]: editorTrans.editPhoto,
        [InlineEditorActions.EDIT_THEME_COLOR]: editorTrans.editColor,
        [InlineEditorActions.ADD_PHOTO]: editorTrans.addPagePhoto
      };
    },
    [editorTrans]
  );

  const generatedActionList = Object.keys(actionData)
    .map(actionName => ({
      id: actionName as InlineEditorActions,
      icon: ACTION_TO_ICON_MAPPER(actionData.visibility?.visibility)[actionName as InlineEditorActions],
      onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        onActionClick(actionName as InlineEditorActions);
        const action = actionData[actionName as InlineEditorActions];
        if (action) {
          if (typeof action === 'function') {
            action(); // Call if it's a simple function
          } else if (typeof action === 'object' && action.function) {
            action.function(); // Handle visibility specific case
          }
        }
      }
    }))
    // filters out the font action if the font pack experiment is not enabled
    .filter(data => data.id !== InlineEditorActions.FONT || fontPackExperimentEnabled);

  const applyScaling = useCallback((value: number) => value / scaleValue, [scaleValue]);

  return (
    <Flex position="relative" flexDirection="column" alignItems="center" gap={pxToRem(applyScaling(10))} zIndex={1300}>
      <Flex
        flexDirection={actionView === 'compact' ? 'row' : 'column'}
        alignItems={actionView === 'compact' ? 'flex-start' : 'center'}
        gap={actionView === 'compact' ? pxToRem(applyScaling(4)) : pxToRem(applyScaling(16))}
        {...(actionView === 'compact' && {
          padding: pxToRem(applyScaling(8)),
          borderRadius: pxToRem(applyScaling(56)),
          backgroundColor: 'white',
          boxShadow: '0px 7px 27px 0px rgba(44, 41, 37, 0.06), 0px 4px 16px 0px rgba(0, 0, 0, 0.07)'
        })}
        color="mono14"
        pointerEvents="all"
      >
        {generatedActionList.map((action, index) => {
          const Icon = action.icon;
          return actionView === 'compact' ? (
            <TooltipV2
              key={index}
              onMouseEnterDelay={200}
              content={
                <TextV2 typographyVariant="label2" fontSize={pxToRem(applyScaling(13))} textAlign="center" color="mono4">
                  {labelTexts(actionData.visibility?.visibility)[action.id]}
                </TextV2>
              }
              placement="top"
            >
              <Flex
                width={pxToRem(applyScaling(40))}
                height={pxToRem(applyScaling(40))}
                paddingY={pxToRem(applyScaling(8))}
                alignItems="center"
                justifyContent="center"
                borderRadius={pxToRem(applyScaling(20))}
                _hover={{
                  backgroundColor: 'mono2',
                  color: '#4E06F2'
                }}
                _active={{
                  backgroundColor: 'mono3',
                  color: '#300495'
                }}
                cursor="pointer"
                onClick={action.onClick}
                __css={{ '& > svg': { width: pxToRem(applyScaling(16)), height: pxToRem(applyScaling(16)) } }}
              >
                <Icon size={16} />
              </Flex>
            </TooltipV2>
          ) : (
            <Flex
              key={index}
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              height={pxToRem(applyScaling(56))}
              cursor="pointer"
              gap={pxToRem(applyScaling(12))}
              paddingX={pxToRem(applyScaling(32))}
              borderRadius={pxToRem(applyScaling(56))}
              boxShadow="0px 7px 27px 0px rgba(44, 41, 37, 0.06), 0px 4px 16px 0px rgba(0, 0, 0, 0.07)"
              backgroundColor="white"
              color="mono14"
              _hover={{
                backgroundColor: 'mono2',
                color: '#4E06F2'
              }}
              _active={{
                backgroundColor: 'mono3',
                color: '#300495'
              }}
              onClick={action.onClick}
              __css={{ '& > svg': { width: pxToRem(applyScaling(24)), height: pxToRem(applyScaling(24)) } }}
            >
              <Icon size={24} />
              <TextV2 typographyVariant="button2" fontSize={pxToRem(applyScaling(16))} fontFamily="Inter UI" fontWeight={600}>
                {labelTexts(actionData.visibility?.visibility)[action.id]}
              </TextV2>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

ActionBar.displayName = 'ActionBar';
