import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { dialogOverridesDesktop, dialogOverridesMobile, StyledAnnouncementContent } from './Announcement.styles';
import { Box, ButtonV2, DialogV2, IconButton, useDisclosure, TextV2 } from '@withjoy/joykit';
import { shouldRenderGraphicAccent } from '@apps/guest/packages/layout-engine/layouts/layout.utils';
import { ReactComponent as ChatIcon } from '@assets/icons/iconChat.svg';
import GraphicAccent from '@apps/guest/packages/layout-engine/components/GraphicAccent';
import { Close } from '@withjoy/joykit/icons';
import { useLayoutEngineTranslations } from './Announcement.i18n';
import { Linkify } from '@shared/components';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import {
  body4ToParagraphOverride,
  button1ToButtonsOverride,
  button2ToButtonsOverride,
  hed4ToHeadingOverride
} from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
export interface AnnouncementProps
  extends Readonly<{
    title: string;
    content: ReactElement;
    graphicAccent: Maybe<string> | undefined;
    onAnnouncementClosed?: () => void;
  }> {}

const Announcement: React.FC<AnnouncementProps> = ({ title, content, graphicAccent, onAnnouncementClosed }) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));
  const dialogRef = useRef<HTMLDivElement>(null);
  const { closeText, continueText } = useLayoutEngineTranslations();
  const [showAnnouncement, setShowAnnouncement] = useState(false);

  const handleCloseAnnouncement = useCallback(() => {
    onClose();
    onAnnouncementClosed && onAnnouncementClosed();
  }, [onClose, onAnnouncementClosed]);

  useEffect(() => {
    let timeoutAnnouncement: Maybe<NodeJS.Timeout> = null;
    if (isOpen) {
      timeoutAnnouncement = setTimeout(() => {
        setShowAnnouncement(true);
      }, 1000);
    }
    return () => {
      if (timeoutAnnouncement) {
        clearTimeout(timeoutAnnouncement);
      }
    };
  }, [isOpen]);

  const graphicAccentMarkup =
    graphicAccent === 'default' ? (
      <Box id="graphic-accent" data-testid={'announcement-accent'} display={'flex'} justifyContent={'center'} marginBottom={9}>
        <ChatIcon />
      </Box>
    ) : shouldRenderGraphicAccent(graphicAccent) ? (
      <GraphicAccent id="graphic-accent" marginBottom={5} display={'flex'} justifyContent={'center'} data-testid={'announcement-accent'} accentId={graphicAccent!} />
    ) : null;

  const overrides = isMobile ? dialogOverridesMobile : dialogOverridesDesktop;

  if (!showAnnouncement) return null;

  return (
    <DialogV2 id="Announcement" disableAutoFocus onClose={handleCloseAnnouncement} isOpen={isOpen} overrides={overrides}>
      <DialogV2.Header textAlign={'right'}>
        {isMobile ? (
          <GuestSiteTypographyOverride override={button1ToButtonsOverride}>
            <ButtonV2
              data-testid="close-icon"
              shape="rounded"
              endIcon={() => <Close />}
              size="sm"
              color="mono14"
              backgroundColor={'white'}
              _hover={{ backgroundColor: 'mono1', color: 'mono14' }}
              _active={{ backgroundColor: 'mono2', color: 'mono14' }}
              onClick={handleCloseAnnouncement}
            >
              {closeText}
            </ButtonV2>
          </GuestSiteTypographyOverride>
        ) : (
          <IconButton
            shape="rounded"
            color="mono14"
            aria-label={closeText}
            backgroundColor={'white'}
            _hover={{ backgroundColor: 'mono1', color: 'mono14' }}
            _active={{ backgroundColor: 'mono2', color: 'mono14' }}
            onClick={handleCloseAnnouncement}
          >
            <Close />
          </IconButton>
        )}
      </DialogV2.Header>
      <DialogV2.Body
        ref={dialogRef}
        paddingX={[6, 8]}
        marginBottom={6}
        overflowX={'hidden'}
        overflowY={'auto'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent="flex-start"
      >
        {graphicAccentMarkup}
        <Box>
          <GuestSiteTypographyOverride override={hed4ToHeadingOverride}>
            <TextV2 data-testid="title" typographyVariant="hed4" textTransform="none" textAlign="center">
              {title}
            </TextV2>
          </GuestSiteTypographyOverride>
        </Box>
        <Box marginTop={8}>
          <GuestSiteTypographyOverride override={body4ToParagraphOverride}>
            <StyledAnnouncementContent wordBreak="break-word" data-testid="content" typographyVariant="body4" textTransform={'none'}>
              <Linkify>{content}</Linkify>
            </StyledAnnouncementContent>
          </GuestSiteTypographyOverride>
        </Box>
        <Box textAlign="center" marginTop={8}>
          <GuestSiteTypographyOverride override={button2ToButtonsOverride}>
            <ButtonV2
              data-testid="continue-btn"
              variant="outline"
              shape="rounded"
              color="mono14"
              backgroundColor="white"
              borderColor="mono5"
              _hover={{ backgroundColor: 'mono1', borderColor: 'mono14', borderWidth: '2px', color: 'mono14' }}
              _active={{ backgroundColor: 'mono2', borderColor: 'mono14', borderWidth: '2px', color: 'mono14' }}
              onClick={handleCloseAnnouncement}
            >
              {continueText}
            </ButtonV2>
          </GuestSiteTypographyOverride>
        </Box>
      </DialogV2.Body>
    </DialogV2>
  );
};

Announcement.displayName = 'Announcement';

export { Announcement };
