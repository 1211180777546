import { useMemo } from 'react';
import { AccommodationCombined, AccommodationCombinedType } from '../components/Accommodations/Accommodation.types';
import { withWindow } from '@shared/utils/withWindow';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useCustomPageTelemetry } from '../../../Custom.telemetry';
import { useTranslation } from '@shared/core';
import { useHotelBookingRoutePaths } from '@apps/guest/routes/HotelBooking/HotelBooking.routes';
import { useGuestSiteState } from '@apps/guest/routes/GuestSite/GuestSite.state.provider';
import { useIncrementInterestedGuestCountMutation } from '@graphql/generated';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useAccommodationPlaceRoutePaths } from '@apps/guest/routes/HotelBooking/routes/AccommodationPlacePartner/AccommodationPlacePartner.utils';
import queryString from 'query-string';
import { config } from '@static/js/env.config';

interface AccommodationsCtaArgs {
  eventHandle: string;
  displayName?: AccommodationCombined['displayName'];
  type: AccommodationCombinedType;
  isFavorite?: AccommodationCombined['isFavorite'];
  pricePerNight?: AccommodationCombined['pricePerNight'];
  strikeoutPricePerNight?: AccommodationCombined['strikeoutPricePerNight'];
  zhId: AccommodationCombined['zhId'];
  url: AccommodationCombined['url'];
  id: AccommodationCombined['id'];
  interestedGuestCount: AccommodationCombined['interestedGuestCount'];
  clickedFrom?: 'tileFavorite' | 'dialog';
  hotelTileGuestCountEnabled?: boolean;
  setRedirectLink?: (redirectLink: string) => void;
}

export const useAccommodationsCta = (props: AccommodationsCtaArgs) => {
  const {
    eventHandle,
    displayName,
    type,
    pricePerNight,
    isFavorite,
    strikeoutPricePerNight,
    url,
    id,
    interestedGuestCount,
    clickedFrom,
    hotelTileGuestCountEnabled,
    zhId,
    setRedirectLink
  } = props;

  const { roomblock, buildPath } = useHotelBookingRoutePaths();
  const [incrementInterestedGuestCount] = useIncrementInterestedGuestCountMutation();
  const { setShowNoLeadDialog, enabledAdminGuestSiteBannerDialog } = useGuestSiteState();
  const guestLinkUseRedirectService = useFeatureValue('guestLinkUseRedirectService').value === 'on';
  const accommodationsGuestSafeLinkEnabled = useFeatureValue('accommodationsGuestSafeLinkEnabled').value === 'on';
  const accommodationsPlacesV2Enabled = useFeatureValue('accommodationsPlacesV2Enabled').value === 'on';
  const { accommodationPlace } = useAccommodationPlaceRoutePaths();

  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');

  const { ctaTitle } = useMemo(() => {
    const buttonCta = {
      [AccommodationCombinedType.Custom]: accTrans.selectCTACustom(),
      [AccommodationCombinedType.Hotel]: accTrans.selectCTAHotel(),
      [AccommodationCombinedType.Roomblock]: accTrans.selectCTARoomBlock()
    };
    return {
      ctaTitle: buttonCta[type]
    };
  }, [type, accTrans]);

  const telemetry = useCustomPageTelemetry();
  const telemetryHotelInfo = {
    hotelName: displayName || '',
    isHotelPreferred: isFavorite || false,
    hotelPrice: pricePerNight || undefined,
    hotelStrikeThroughPrice: strikeoutPricePerNight || undefined,
    clickedFrom,
    accommodationsType: type
  };

  const handleOnSelectAccommodation = useEventCallback(() => {
    if (type === AccommodationCombinedType.Roomblock) {
      handleOnSelectRoomBlock();
      return;
    }
    telemetry.accommodationsLinkInteracted(accommodationPlace.goToPath(eventHandle, id), telemetryHotelInfo);
    if (accommodationsPlacesV2Enabled) {
      withWindow(global => global.open(`${accommodationPlace.goToPath(eventHandle, id)}`, '_blank'));
    } else if (url) {
      const redirectServicePath = `/eventredirect/`;
      if (accommodationsGuestSafeLinkEnabled && !url.includes(redirectServicePath)) {
        setRedirectLink?.(url);
        return;
      } else if (!accommodationsGuestSafeLinkEnabled) {
        if (guestLinkUseRedirectService && !url.includes(redirectServicePath)) {
          withWindow(global => global.open(queryString.stringifyUrl({ url: `${config.redirectServiceUri}/redirect`, query: { url } }), '_blank'));
          return;
        } else if (!guestLinkUseRedirectService && !zhId && url.includes(redirectServicePath)) {
          let hotelWebsiteUrl: URL | undefined;
          if (url) {
            try {
              hotelWebsiteUrl = new URL(url);
            } catch {}
          }
          withWindow(global => global.open(hotelWebsiteUrl?.searchParams.get('destination') || url, '_blank'));
          return;
        }
      }
      withWindow(global => global.open(url, '_blank'));
    }
  });

  const handleOnSelectRoomBlock = useEventCallback(() => {
    if (enabledAdminGuestSiteBannerDialog) {
      telemetry.leadGenDialogPromptedClicked();
      setShowNoLeadDialog(true);
    } else {
      if (hotelTileGuestCountEnabled) {
        incrementInterestedGuestCount({
          variables: { reservedRoomBlockId: id }
        });
      }
      const telemetryHotelInfoWithInterestCountIfAvailable = {
        ...telemetryHotelInfo,
        displayedInterestedGuestCount: hotelTileGuestCountEnabled ? interestedGuestCount || 0 : undefined
      };
      telemetry.accommodationsLinkInteracted(buildPath(eventHandle, roomblock, id), telemetryHotelInfoWithInterestCountIfAvailable);
      withWindow(global => global.open(`${global.location.origin}${buildPath(eventHandle, roomblock, id)}`, '_blank'));
    }
  });

  return {
    ctaTitle,
    handleOnSelectAccommodation
  };
};
