import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { MapMarker } from '@withjoy/joykit/icons';
import { AccommodationCombinedType } from '../../Accommodation.types';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { useFeatureValue } from '@shared/core/featureFlags';
import { body2ToParagraphOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { pxToRem } from '@withjoy/joykit/theme';
import { Category } from '@graphql/generated';
import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';
import { getHotelAddress } from '@apps/admin/routes/WebsiteEditor/routes/WebsiteEditorCustomPageV2/components/CustomPageLeftPanel/components/CustomComponents/TravelMap/utils/Accommodations.utils';

export const AccommodationTileAddress: React.FC<{
  type: AccommodationCombinedType;
  address?: string | null;
  customAddress?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  country?: string | null;
}> = ({ address, customAddress, city, state, postalCode, country, type }) => {
  const accommodationsPlacesV2Enabled = useFeatureValue('accommodationsPlacesV2Enabled').value === 'on';
  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';
  const { scaleFontSize } = useScaleGuestSiteFontSize();

  if (type === AccommodationCombinedType.Roomblock || !address) return null;

  return (
    <Flex flexDirection={'row'} columnGap={5}>
      <Flex>
        <MapMarker size={32} />
      </Flex>
      <Flex flexDirection={'column'} justifyContent={'center'}>
        <GuestSiteTypographyOverride override={body2ToParagraphOverride} defaultFontFamily="Inter UI">
          <TextV2
            {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
            fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
            lineHeight={'22.5px'}
            letterSpacing={'-0.15px'}
            paddingBottom={1}
          >
            {accommodationsPlacesV2Enabled ? customAddress || getHotelAddress({ address, city, state, postalCode, country }) : address}
          </TextV2>
        </GuestSiteTypographyOverride>
      </Flex>
    </Flex>
  );
};
