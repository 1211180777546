import React from 'react';
import { useTranslation } from '@shared/core';
import { Category, PointsOnMapFragment } from '@graphql/generated';
import { Box, Flex, TextV2 } from '@withjoy/joykit';
import { AccommodationCombined, AccommodationCombinedType } from '../Accommodations/Accommodation.types';
import { distanceInMiles } from '@shared/utils/distanceInMiles';
import { createDateFromUnformattedString } from '@shared/utils/useDateUtils';
import { pxToRem } from '@withjoy/joykit/theme';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { useLayout } from '@apps/guest/packages/layout-engine/layouts/LayoutProvider';
import { DEFAULT_HOTEL_IMAGE } from '../../TravelMap.constants';
import { SkeletonText, SkeletonThumbnail } from '@shared/components/Skeleton';
import { TextEllipsisWrapper } from '@shared/utils/formatting/textEllipsis';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import {
  body1ToParagraphOverride,
  hed2ToSubHeadingOverride,
  label4ToParagraphOverride
} from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';
import { boxShadowValuesV2 } from '@shared/utils/style/dropShadows';

interface AccommodationsTileV2Props extends AccommodationCombined {
  idx: number;
  locationInfo?: PointsOnMapFragment;
  eventHandle: string;
  showDefaultData?: boolean;
  onSelectHotel: (id: string) => void;
  setLocationBeingHovered?: (id: string | undefined) => void;
  hotelTileGuestCountEnabled?: boolean;
}

const AccommodationsTileV2: React.FC<AccommodationsTileV2Props> = props => {
  const {
    idx,
    displayName,
    photo,
    cutoffDate,
    strikeoutPricePerNight,
    pricePerNight,
    id,
    type,
    showDefaultData,
    locationInfo,
    onSelectHotel,
    interestedGuestCount,
    hotelTileGuestCountEnabled,
    setLocationBeingHovered
  } = props;
  const { layout } = useLayout();
  const isBrannan = layout === 'brannan';
  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');
  const { scaleFontSize } = useScaleGuestSiteFontSize();
  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';

  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));
  const isMobileOrTablet = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'md' }));
  const haveDistanceToVenue = locationInfo?.latitude && locationInfo?.longitude && props.latitude && props.longitude;
  const milesToVenue = distanceInMiles(locationInfo?.latitude || 0, locationInfo?.longitude || 0, props.latitude || 0, props.longitude || 0).toFixed(1);

  return (
    <Flex
      flex="1 1 calc(33.33% - 10px)"
      padding={6}
      borderRadius={pxToRem(12)}
      onClick={() => onSelectHotel(id)}
      __css={{ _hover: { cursor: 'pointer', boxShadow: '0px 7px 27px 0px rgba(44, 41, 37, 0.06), 0px 4px 16px 0px rgba(0, 0, 0, 0.07)' } }}
      onMouseEnter={() => !isMobileOrTablet && setLocationBeingHovered?.(id)}
      onMouseLeave={() => !isMobileOrTablet && setLocationBeingHovered?.(undefined)}
    >
      <Flex width={'100%'} justifyContent="center" position="relative">
        <Flex justifyContent="space-between" position="absolute" top={4} left={4} right={4} zIndex={1} flexWrap="wrap" gap={4}>
          <Flex justifyContent="center" alignItems="center" columnGap={2} width={32} height={32} borderRadius="50%" backgroundColor="white" boxShadow={boxShadowValuesV2.xxxl}>
            <TextV2 typographyVariant="label3" fontSize={pxToRem(15)} fontWeight={500} fontFamily="Inter UI" color="mono14">
              {+idx + 1}
            </TextV2>
          </Flex>
          {interestedGuestCount && hotelTileGuestCountEnabled ? (
            <Flex
              backgroundColor="white"
              boxShadow="0px 7px 27px 0px rgba(44, 41, 37, 0.06), 0px 4px 16px 0px rgba(0, 0, 0, 0.07)"
              borderRadius={pxToRem(20.632)}
              height={pxToRem(32)}
              alignItems="center"
              paddingX={4}
            >
              <TextV2 fontFamily={'Inter UI'} color={'black'} fontSize={pxToRem(13)} letterSpacing={pxToRem(-0.032)} fontWeight={600} alignSelf={'center'}>
                {accTrans.guestsInterested({ interestedGuestCount })}
              </TextV2>
            </Flex>
          ) : null}
        </Flex>
        <Flex width={'100%'}>
          <Flex width={'100%'} flexDirection="column" gap={19}>
            <Box
              width="100%"
              height={pxToRem(206)}
              borderRadius={pxToRem(12)}
              objectFit={'cover'}
              backgroundSize={'cover'}
              backgroundPosition={`center ${showDefaultData ? 'bottom' : 'center'}`}
              backgroundRepeat={'no-repeat'}
              backgroundImage={`url(${photo?.url || DEFAULT_HOTEL_IMAGE})`}
              display="flex"
              position={'relative'}
            />
            <Flex flexDirection="column" gap={5}>
              <Flex flexDirection="column" gap={2}>
                <GuestSiteTypographyOverride override={hed2ToSubHeadingOverride} defaultFontFamily="Inter UI">
                  <TextEllipsisWrapper lineClamp={1}>
                    <TextV2
                      typographyVariant="hed2"
                      {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                      fontSize={scaleFontSize(pxToRem(17), Category.SUB_HEADING)}
                      fontWeight={600}
                      color="mono14"
                    >
                      {displayName}
                    </TextV2>
                  </TextEllipsisWrapper>
                </GuestSiteTypographyOverride>
                {haveDistanceToVenue && (
                  <GuestSiteTypographyOverride override={body1ToParagraphOverride} defaultFontFamily="Inter UI">
                    <TextV2
                      typographyVariant="body1"
                      {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                      fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
                      fontWeight={400}
                      color="mono10"
                    >
                      {accTrans.milesFromVenue({ miles: milesToVenue })}
                    </TextV2>
                  </GuestSiteTypographyOverride>
                )}
              </Flex>
              {type === AccommodationCombinedType.Roomblock ? (
                <Flex justifyContent="space-between">
                  <Flex flexDirection="column">
                    <GuestSiteTypographyOverride override={label4ToParagraphOverride} defaultFontFamily="Inter UI">
                      <TextV2
                        typographyVariant="label4"
                        {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                        fontSize={scaleFontSize(pxToRem(12), Category.PARAGRAPH)}
                        fontWeight={600}
                        color="mono10"
                        textTransform="uppercase"
                      >
                        {accTrans.bookBefore()}
                      </TextV2>
                    </GuestSiteTypographyOverride>
                    <GuestSiteTypographyOverride override={label4ToParagraphOverride} defaultFontFamily="Inter UI">
                      <TextV2
                        typographyVariant="label4"
                        {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                        fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
                        fontWeight={600}
                        color="mono14"
                      >
                        {createDateFromUnformattedString(cutoffDate || '').toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}
                      </TextV2>
                    </GuestSiteTypographyOverride>
                  </Flex>
                  {pricePerNight && (
                    <Flex flexDirection="column">
                      <GuestSiteTypographyOverride override={label4ToParagraphOverride} defaultFontFamily="Inter UI">
                        <TextV2
                          typographyVariant="label4"
                          {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                          fontSize={scaleFontSize(pxToRem(12), Category.PARAGRAPH)}
                          fontWeight={600}
                          color="mono10"
                          textTransform="uppercase"
                          textAlign="right"
                        >
                          {accTrans.groupRate()}
                        </TextV2>
                      </GuestSiteTypographyOverride>
                      <Flex gap={1} justifyContent="flex-end">
                        <GuestSiteTypographyOverride override={label4ToParagraphOverride} defaultFontFamily="Inter UI">
                          <TextV2
                            typographyVariant="label4"
                            {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                            fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
                            fontWeight={600}
                            color="mono14"
                            textAlign="right"
                          >
                            ${pricePerNight}
                          </TextV2>
                        </GuestSiteTypographyOverride>
                        <GuestSiteTypographyOverride override={label4ToParagraphOverride} defaultFontFamily="Inter UI">
                          <TextV2
                            typographyVariant="label4"
                            {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                            fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
                            fontWeight={400}
                            color="mono14"
                            textAlign="right"
                            textTransform="lowercase"
                          >
                            {accTrans.night()}
                          </TextV2>
                        </GuestSiteTypographyOverride>
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              ) : (
                <>
                  {(pricePerNight || (!isMobile && isBrannan)) && (
                    <Flex gap={2} justifyContent="flex-end">
                      {strikeoutPricePerNight && (
                        <GuestSiteTypographyOverride override={hed2ToSubHeadingOverride} defaultFontFamily="Inter UI">
                          <TextV2
                            typographyVariant="hed2"
                            {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                            fontSize={scaleFontSize(pxToRem(16), Category.SUB_HEADING)}
                            fontWeight={600}
                            color="mono8"
                            backgroundSize={'100% 1.5px'}
                            width={'auto'}
                            backgroundImage={'linear-gradient(currentColor, currentColor)'}
                            backgroundPosition={'0% 50%'}
                            backgroundRepeat={'no-repeat'}
                            textAlign="right"
                          >
                            ${strikeoutPricePerNight.toString()}
                          </TextV2>
                        </GuestSiteTypographyOverride>
                      )}
                      {pricePerNight ? (
                        <Flex gap={1}>
                          <GuestSiteTypographyOverride override={hed2ToSubHeadingOverride} defaultFontFamily="Inter UI">
                            <TextV2
                              typographyVariant="hed2"
                              {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                              fontSize={scaleFontSize(pxToRem(16), Category.SUB_HEADING)}
                              fontWeight={600}
                              color="mono14"
                              textAlign="right"
                            >
                              ${pricePerNight}
                            </TextV2>
                          </GuestSiteTypographyOverride>
                          <GuestSiteTypographyOverride override={hed2ToSubHeadingOverride} defaultFontFamily="Inter UI">
                            <TextV2
                              typographyVariant="hed2"
                              {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                              fontSize={scaleFontSize(pxToRem(16), Category.SUB_HEADING)}
                              fontWeight={400}
                              color="mono14"
                              textAlign="right"
                              textTransform="lowercase"
                            >
                              ${pricePerNight}
                            </TextV2>
                          </GuestSiteTypographyOverride>
                        </Flex>
                      ) : (
                        <Flex height={pxToRem(21.75)} />
                      )}
                    </Flex>
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

AccommodationsTileV2.displayName = 'AccommodationsTileV2';

const AccommodationsTileV2Skeleton: React.FC = () => {
  return (
    <Flex flex="1 1 calc(33.33% - 10px)" padding={6} borderRadius={pxToRem(12)}>
      <Flex flexDirection="column" width="100%">
        <SkeletonThumbnail
          height={pxToRem(206)}
          overrides={{
            PlaceholderRoot: {
              props: {
                width: '100%',
                backgroundColor: 'white',
                borderRadius: '12px !important'
              }
            }
          }}
        />
        <Box width="100%" paddingTop={6}>
          <SkeletonText rows={1} width={pxToRem(136)} skeletonHeight={pxToRem(22)} paddingBottom={2} />
          <SkeletonText rows={1} width={pxToRem(206)} skeletonHeight={pxToRem(23)} paddingBottom={5} />
          <SkeletonText width={pxToRem(200)} marginLeft="auto" rows={1} skeletonHeight={pxToRem(25)} />
        </Box>
      </Flex>
    </Flex>
  );
};

export { AccommodationsTileV2, AccommodationsTileV2Skeleton };
