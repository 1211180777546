import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { useTranslation } from '@shared/core';
import { Tags } from '@withjoy/joykit/icons';
import { createDateFromUnformattedString, isSameMonthCheckInCheckOut } from '@shared/utils/useDateUtils';
import { AccommodationCombinedType } from '../../Accommodation.types';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { useFeatureValue } from '@shared/core/featureFlags';
import { body2ToParagraphOverride, label2ToParagraphOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';
import { pxToRem } from '@withjoy/joykit/theme';
import { Category } from '@graphql/generated';

export const AccommodationTileDiscount: React.FC<{
  strikeoutPricePerNight?: number | null;
  checkInDate?: string | null;
  checkOutDate?: string | null;
  pricePerNight?: number | null;
  type: AccommodationCombinedType;
}> = ({ strikeoutPricePerNight, checkInDate, checkOutDate, pricePerNight, type }) => {
  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');

  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';
  const { scaleFontSize } = useScaleGuestSiteFontSize();

  if (type !== AccommodationCombinedType.Roomblock) return null;
  return (
    <Flex flexDirection={'row'} columnGap={5}>
      <Flex>
        <Tags size={'xl'} />
      </Flex>
      <Flex flexDirection={'column'} justifyContent={'center'}>
        <GuestSiteTypographyOverride override={body2ToParagraphOverride} defaultFontFamily="Inter UI">
          <Flex flexDirection={'row'} columnGap={1} paddingBottom={1}>
            {strikeoutPricePerNight ? (
              <>
                <TextV2
                  backgroundSize={'100% 1.5px'}
                  width={'auto'}
                  backgroundImage={'linear-gradient(currentColor, currentColor)'}
                  backgroundPosition={'0% 50%'}
                  backgroundRepeat={'no-repeat'}
                  {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                  fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
                  fontWeight={700}
                  color={'mono10'}
                >
                  ${strikeoutPricePerNight.toString()}
                </TextV2>
                <TextV2 color="mono14" {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })} fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)} fontWeight={700}>
                  ${pricePerNight}
                </TextV2>
              </>
            ) : (
              <>
                <TextV2 color="mono14" {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })} fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)} fontWeight={700}>
                  ${pricePerNight}{' '}
                </TextV2>
              </>
            )}
            <TextV2 {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}>{accTrans.night()}</TextV2>
          </Flex>
        </GuestSiteTypographyOverride>
        <GuestSiteTypographyOverride override={label2ToParagraphOverride} defaultFontFamily="Inter UI">
          <TextV2 typographyVariant={'label2'} {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}>
            {createDateFromUnformattedString(checkInDate || '').toLocaleDateString('en-US', { month: 'short', day: '2-digit' })} -{' '}
            {isSameMonthCheckInCheckOut(checkInDate || '', checkOutDate || '')
              ? createDateFromUnformattedString(checkOutDate || '').toLocaleDateString('en-US', { day: '2-digit' })
              : createDateFromUnformattedString(checkOutDate || '').toLocaleDateString('en-US', { month: 'short', day: '2-digit' })}
            {', '}
            {createDateFromUnformattedString(checkInDate || '').toLocaleDateString('en-US', { year: 'numeric' })}
          </TextV2>
        </GuestSiteTypographyOverride>
      </Flex>
    </Flex>
  );
};
