import React, { useEffect, useRef, useState } from 'react';
import { pxToRem } from '@withjoy/joykit/theme';
import { HeartFilled } from '@withjoy/joykit/icons';
import { Flex, Box, TextV2 } from '@withjoy/joykit';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { CircleMapPin } from '@shared/components/JoyInteractableMap/MapPin';
import { DirectionsRenderer } from '@react-google-maps/api';
import { JoyInteractableMap, MAP_CARDS_PORTAL_ID } from '@shared/components/JoyInteractableMap';
import { getCalculatedCenterAndZoom } from '@shared/components/JoyInteractableMap/JoyInteractableMap.utils';

interface DirectionsMapProps {
  destination: { lat: number; lng: number };
  originList: { id: string; index?: number; lat: number; lng: number }[];
  locationBeingHovered?: string;
  gestureHandling?: 'cooperative' | 'greedy' | 'none';
  hideDirection?: boolean;
  onMapClick?: () => void;
  onOriginClick?: (id: string) => void;
}

const DirectionsMap: React.FC<DirectionsMapProps> = props => {
  const directionsService = useRef(new google.maps.DirectionsService());

  const { destination, originList, onOriginClick, locationBeingHovered, gestureHandling, hideDirection: hideDirectionProps, onMapClick, children } = props;
  const hasDestination = destination.lat !== 0 && destination.lng !== 0;
  const hideDirection = !hasDestination || hideDirectionProps;

  const markers = originList
    .map(origin => ({ lat: origin.lat, lng: origin.lng }))
    .concat(destination)
    .filter(marker => marker.lat !== 0 || marker.lng !== 0);

  const { center, bounds } = getCalculatedCenterAndZoom(markers);

  const [latlng, setLatlng] = useState<{ lat: number; lng: number }>(center);
  const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null);

  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));

  const handleOnCenterChanged = useEventCallback((lat: number, lng: number) => setLatlng({ lat, lng }));

  useEffect(() => {
    if (originList.length > 0 && destination) {
      const origin = originList[0];
      const request = {
        origin: { lat: origin.lat, lng: origin.lng },
        destination: { lat: destination.lat, lng: destination.lng },
        travelMode: google.maps.TravelMode.DRIVING
      };

      directionsService.current.route(request, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
        }
      });
    }
  }, [originList, destination]);

  return (
    <JoyInteractableMap
      center={latlng}
      {...(markers.length > 1 && { bounds: bounds })}
      zoom={13}
      autoCenterAndZoom={markers.length > 0}
      gestureHandling={gestureHandling}
      onClick={onMapClick}
      onCenterChanged={handleOnCenterChanged}
    >
      {children}
      {!hideDirection && directions && (
        <DirectionsRenderer
          directions={directions}
          options={{
            suppressMarkers: true,
            polylineOptions: {
              strokeColor: '#494DCB',
              strokeWeight: 3
            }
          }}
        />
      )}
      {hasDestination && (
        <CircleMapPin
          isDestination
          position={destination}
          showOnTop
          label={
            <Flex justifyContent="center" alignItems="center" borderRadius="50%" border="16px solid rgba(73, 77, 203, 0.20)">
              <HeartFilled size="md" color="white" />
            </Flex>
          }
        />
      )}

      {isMobile && <Box id={MAP_CARDS_PORTAL_ID} position="fixed" width={`calc(100% - ${pxToRem(32)})`} padding={5} bottom={0} left={0} />}
      {originList.map((origin, index) => {
        const isHighlighted = origin.id === locationBeingHovered;
        return (
          <CircleMapPin
            key={origin.id}
            position={{ lat: origin.lat, lng: origin.lng }}
            isActive={isHighlighted}
            showOnTop={isHighlighted}
            {...(onOriginClick && {
              onClick: e => {
                e.stopPropagation();
                onOriginClick(origin.id);
              }
            })}
            onDoubleClick={e => {
              e.stopPropagation();
            }}
            label={
              <Flex justifyContent="center" alignItems="center" columnGap={2}>
                <TextV2 typographyVariant="label3" fontSize={pxToRem(15)} fontWeight={500} fontFamily="Inter UI">
                  {origin.index ? origin.index + 1 : ++index}
                </TextV2>
              </Flex>
            }
          />
        );
      })}
    </JoyInteractableMap>
  );
};

DirectionsMap.displayName = 'DirectionsMap';

export { DirectionsMap };
