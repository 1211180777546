import { AccommodationHotelsQuery, AccommodationPlaceType, ReservedRoomBlockStatus } from '@graphql/generated';
import { AccommodationItemList, TravelMapBlockType } from '../TravelMap.types';

interface DetailAddressArgs {
  address?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  country?: string | null;
}

export const getHotelAddress = ({ address, city, state, postalCode, country }: DetailAddressArgs) => {
  const fullAddress = `${address ?? ''}${city ? ', ' + city : ''}${state || postalCode ? ', ' : ''}${state ?? ''}${postalCode ? ' ' + postalCode : ''}${
    country ? ', ' + country : ''
  }`;
  return fullAddress;
};

export const generateAccommodationList: (accommodation: AccommodationHotelsQuery['eventByName'], accommodationsPlacesV2Enabled?: boolean) => AccommodationItemList[] = (
  accommodation,
  accommodationsPlacesV2Enabled
) => {
  return [
    ...(accommodation?.hotelRoomBlocks?.reservedRoomBlocks?.filter(roomBlock => roomBlock.status === ReservedRoomBlockStatus.active) || []).map(roomblock => ({
      id: roomblock.id,
      type: TravelMapBlockType.travelMapAccommodationsRoomblock,
      isFavorite: roomblock.isFavorite,
      photoUrl: roomblock.photo?.url || '',
      address: roomblock.address || '',
      cutoffDate: roomblock.cutoffDate || '',
      displayName: roomblock.displayName || '',
      status: roomblock.status,
      isPhotoHidden: false
    })),
    ...(accommodationsPlacesV2Enabled
      ? [
          ...(accommodation?.accommodationPlacesV2 || []).map(accPlaceV2 => ({
            id: accPlaceV2.id,
            type: accPlaceV2.type === AccommodationPlaceType.hotel ? TravelMapBlockType.travelMapAccommodationsHotel : TravelMapBlockType.travelMapAccommodationsCustom,
            isFavorite: false,
            photoUrl: accPlaceV2.customPhoto?.url || accPlaceV2.photo?.url || '',
            isPhotoHidden: !!accPlaceV2.isPhotoHidden,
            address:
              accPlaceV2.customAddress ||
              getHotelAddress({ address: accPlaceV2.address, city: accPlaceV2.city, state: accPlaceV2.state, postalCode: accPlaceV2.postalCode, country: accPlaceV2.country }) ||
              '',
            cutoffDate: '',
            displayName: accPlaceV2.customName || accPlaceV2.name || ''
          }))
        ]
      : [
          ...(accommodation?.accommodationPlaces || []).map(accPlace => ({
            id: accPlace.id,
            type: accPlace.type === AccommodationPlaceType.hotel ? TravelMapBlockType.travelMapAccommodationsHotel : TravelMapBlockType.travelMapAccommodationsCustom,
            isFavorite: false,
            photoUrl: accPlace.displayPhoto?.url ?? '',
            address: accPlace.joyPlace.address || '',
            cutoffDate: '',
            displayName: accPlace.joyPlace.name || '',
            isPhotoHidden: false
          }))
        ])
  ];
};
