import React from 'react';
import { useHistory, useRouteMatch } from '@shared/core';
import { createContext } from '@shared/utils/createContext';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { OccasionName } from '@apps/imagineYourCard/Components/ImagineYourCardRoot/hooks/useImagineOptions';
import { withWindow } from '@shared/utils/withWindow';

export const CARDS_BASE_PATH = '/cards';

const createPaths = (
  path: string | undefined,
  options: {
    public: boolean;
    admin: boolean;
  }
) => {
  const paths = [];

  if (options.public) {
    paths.push(`${CARDS_BASE_PATH}${path}`);
  }

  if (options.admin) {
    paths.push(`/:eventHandle/edit${CARDS_BASE_PATH}${path}`);
  }

  return { paths };
};

const CARD_ROUTES = {
  dashboard: createPaths('', { public: true, admin: true }),
  imagine: createPaths('/:occasion/:category/imagine/', { public: true, admin: true }),
  designsGallery: createPaths('/:occasion/:category', { public: true, admin: true }),
  drafts: createPaths('/drafts', { public: false, admin: true }),
  favorites: createPaths('/favorites', { public: false, admin: true }),
  productDetailsPage: createPaths('/:occasion/:category/product/:themeId', { public: true, admin: true }),
  paperCardCustomizer: createPaths('/customize/:cardDraftId', { public: false, admin: true }),
  digitalCardCustomizer: createPaths('/digital/customize/:cardDraftId', { public: false, admin: true }),

  editOrder: createPaths('/orders/:printOrderNumber/customize', { public: false, admin: true }),
  editDigitalOrder: createPaths('/orders/:printOrderNumber/digital/customize', { public: false, admin: true }),
  orderHistory: createPaths('/orders', { public: false, admin: true }),
  orderDetails: createPaths('/orders/:printOrderNumber', { public: false, admin: true }),

  designConcierge: createPaths('/concierge', { public: false, admin: true })
} as const;

type CardsRouterContext = {
  getCustomizeDraftPath: (cardDraftId: string) => string;
  getConciergePath: (setName: string) => string;
  getOrderDetailsPath: (printOrderNumber: string) => string;
  getCustomizeOrderDetailsPath: (printOrderNumber: string) => string;
  getOrdersPath: () => string;
  getDraftsPath: () => string;
  getFavoritesPath: () => string;
  getCardImaginePath: (occasion?: OccasionName, category?: StationeryTemplateCategoryEnum) => string;
  getDigitalCustomizeDraftPath: (cardDraftId: string) => string;
  getShopPath: (occasion: 'wedding', category?: StationeryTemplateCategoryEnum) => string;
  getProductDetailsPagePath: (occasion: 'wedding', category: StationeryTemplateCategoryEnum, themeId: string) => string;
  getPublicRootPath: () => string;

  goToCustomizeDraft: (draftId: string) => void;
  goToConcierge: (setName: string) => void;
  goToEventDashboard: () => void;
  goToOrderDetails: (orderNumber: string) => void;
  goToCustomizeOrderDetails: (orderNumber: string) => void;
  goToCustomizeDigitalOrderDetails: (orderNumber: string) => void;
  goToCardDrafts: () => void;
  goToOrders: () => void;
  goToDigitalCustomizeDraft: (theme: string) => void;
  goToShop: (occasion: 'wedding', category?: StationeryTemplateCategoryEnum) => void;
  goToProductDetailsPage: (occasion: 'wedding', category: StationeryTemplateCategoryEnum, themeId: string) => void;
  goToMessageTrackingPage: () => void;
  routes: typeof CARD_ROUTES;

  isPublicShopRoute: boolean;
};

export const NAMED_CATEGORY_TO_STATIONERY_TEMPLATE_ENUM_MAP: Record<string, StationeryTemplateCategoryEnum> = {
  'save-the-date': StationeryTemplateCategoryEnum.saveTheDate,
  invitation: StationeryTemplateCategoryEnum.invitation,
  'thank-you': StationeryTemplateCategoryEnum.thankYou,
  holiday: StationeryTemplateCategoryEnum.holiday
};

const STATIONERY_TEMPLATE_ENUM_TO_NAMED_CATEGORY_MAP: Record<StationeryTemplateCategoryEnum, string> = {
  [StationeryTemplateCategoryEnum.saveTheDate]: 'save-the-date',
  [StationeryTemplateCategoryEnum.invitation]: 'invitation',
  [StationeryTemplateCategoryEnum.thankYou]: 'thank-you',
  [StationeryTemplateCategoryEnum.holiday]: 'holiday'
};

export const getNamedCategoryPathFromStationeryTemplateCategory = (category: StationeryTemplateCategoryEnum) => {
  return STATIONERY_TEMPLATE_ENUM_TO_NAMED_CATEGORY_MAP[category];
};

export const getStationeryTemplateCategoryFromNamedCategoryPath = (categoryPath: string) => {
  return NAMED_CATEGORY_TO_STATIONERY_TEMPLATE_ENUM_MAP[categoryPath];
};

type ValidShopPageMatch = {
  occasion: 'wedding';
  category: 'save-the-date' | 'invitation' | 'thank-you' | 'holiday';
};

export const isValidShopPageMatch = (matchParams: Record<string, string>): matchParams is ValidShopPageMatch => {
  return matchParams.occasion === 'wedding' && matchParams.category in NAMED_CATEGORY_TO_STATIONERY_TEMPLATE_ENUM_MAP;
};

const [Provider, useCardsRouterContext] = createContext<CardsRouterContext>({ name: 'CardsRouteProvider' });

const CardsRoutesProvider = ({ children, eventHandle }: { eventHandle?: string; children: React.ReactNode }) => {
  const history = useHistory();

  const isPublicShopRoute = !!useRouteMatch('/cards');

  const buildRoute = useEventCallback((path: string = '', basePath: string = CARDS_BASE_PATH) => {
    return eventHandle ? `/${eventHandle}/edit${basePath}${path}` : `${basePath}${path}`;
  });

  const getPublicRootPath = useEventCallback(() => {
    return CARDS_BASE_PATH;
  });

  const getDigitalCustomizeDraftPath = useEventCallback<CardsRouterContext['getDigitalCustomizeDraftPath']>(draftId => {
    return buildRoute(`/digital/customize/${draftId}`);
  });

  const getOrderDetailsPath = useEventCallback<CardsRouterContext['getOrderDetailsPath']>(printOrderNumber => {
    return buildRoute(`/orders/${printOrderNumber}`);
  });

  const getCustomizeOrderDetailsPath = useEventCallback<CardsRouterContext['getCustomizeOrderDetailsPath']>(printOrderNumber => {
    return buildRoute(`/orders/${printOrderNumber}/customize`);
  });

  const getCustomizeDigitalOrderDetailsPath = useEventCallback<CardsRouterContext['getCustomizeOrderDetailsPath']>(digitalOrderNumber => {
    return buildRoute(`/orders/${digitalOrderNumber}/digital/customize`);
  });

  const getCustomizeDraftPath = useEventCallback<CardsRouterContext['getCustomizeDraftPath']>(cardDraftId => {
    return buildRoute(`/customize/${cardDraftId}`);
  });

  const getConciergePath = useEventCallback<CardsRouterContext['getConciergePath']>(setName => {
    return buildRoute(`/concierge?setName=${setName}`);
  });

  const getCardImaginePath = useEventCallback((occasion: OccasionName = 'wedding', category?: StationeryTemplateCategoryEnum) => {
    const pathCategory = getNamedCategoryPathFromStationeryTemplateCategory(category ?? StationeryTemplateCategoryEnum.saveTheDate);
    return buildRoute(`/${occasion}/${pathCategory}/imagine`);
  });

  const getOrdersPath = useEventCallback(() => {
    return buildRoute('/orders');
  });
  const getDraftsPath = useEventCallback(() => {
    return buildRoute('/drafts');
  });
  const getFavoritesPath = useEventCallback(() => {
    return buildRoute('/favorites');
  });

  const getShopPath = useEventCallback((occasion: 'wedding', category?: StationeryTemplateCategoryEnum) => {
    return buildRoute(`/${occasion}` + (category ? `/${getNamedCategoryPathFromStationeryTemplateCategory(category)}` : ''));
  });

  const getProductDetailsPagePath = useEventCallback((occasion: 'wedding', category: StationeryTemplateCategoryEnum, themeId: string) => {
    return buildRoute(`/${occasion}/${getNamedCategoryPathFromStationeryTemplateCategory(category)}/product/${themeId}`);
  });

  // Actions

  const goToDigitalCustomizeDraft = useEventCallback((draftId: string) => {
    history.push(getDigitalCustomizeDraftPath(draftId));
  });

  const goToOrders = useEventCallback(() => {
    history.push(getOrdersPath());
  });

  const goToCustomizeDraft = useEventCallback((stationeryDraftId: string) => {
    history.push(getCustomizeDraftPath(stationeryDraftId));
  });

  const goToConcierge = useEventCallback((setName: string) => {
    history.push(getConciergePath(setName));
  });

  const goToEventDashboard = useEventCallback(() => {
    history.push(eventHandle ? `/${eventHandle}/edit` : '/event/edit');
  });

  const goToOrderDetails = useEventCallback((printOrderNumber: string) => {
    history.push(getOrderDetailsPath(printOrderNumber));
  });

  const goToCustomizeOrderDetails = useEventCallback((printOrderNumber: string) => {
    history.push(getCustomizeOrderDetailsPath(printOrderNumber));
  });

  const goToCustomizeDigitalOrderDetails = useEventCallback((digitalOrderNumber: string) => {
    history.push(getCustomizeDigitalOrderDetailsPath(digitalOrderNumber));
  });

  const goToCardDrafts = useEventCallback(() => {
    history.push(getDraftsPath());
  });

  const goToShop = useEventCallback((occasion: 'wedding', category?: StationeryTemplateCategoryEnum) => {
    history.push(getShopPath(occasion, category));
  });

  const goToProductDetailsPage = useEventCallback((occasion: 'wedding', category: StationeryTemplateCategoryEnum, themeId: string) => {
    history.push(getProductDetailsPagePath(occasion, category, themeId));
  });

  const goToMessageTrackingPage = useEventCallback(() => {
    // To be replaced with new message tracking page
    withWindow(global => {
      global.location.href = global.origin + buildRoute('', '/guests');
    });
  });

  return (
    <Provider
      value={{
        getPublicRootPath,
        getCardImaginePath,
        getDraftsPath,
        getFavoritesPath,
        getOrdersPath,
        getCustomizeDraftPath,
        getConciergePath,
        getOrderDetailsPath,
        getCustomizeOrderDetailsPath,
        getShopPath,
        getProductDetailsPagePath,

        goToOrders,
        goToCardDrafts,
        goToEventDashboard,
        goToCustomizeDraft,
        goToConcierge,
        goToOrderDetails,
        goToCustomizeOrderDetails,
        goToCustomizeDigitalOrderDetails,
        getDigitalCustomizeDraftPath,
        goToDigitalCustomizeDraft,
        goToShop,
        goToProductDetailsPage,
        goToMessageTrackingPage,

        routes: CARD_ROUTES,
        isPublicShopRoute
      }}
    >
      {children}
    </Provider>
  );
};

export { CardsRoutesProvider, useCardsRouterContext };

export const getStandalonePathToAdminCardPdp = (eventHandle: string, occasion: 'wedding', category: StationeryTemplateCategoryEnum, themeId: string) => {
  return `/${eventHandle}/edit/cards/${occasion}/${getNamedCategoryPathFromStationeryTemplateCategory(category)}/product/${themeId}`;
};

export const getStandalonePathToAdminCardShop = (eventHandle: string, occasion: 'wedding', themeId?: string) => {
  return `/${eventHandle}/edit/cards/${occasion}${themeId ? `?theme=${themeId}` : ''}`;
};
