import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { useTranslation } from '@shared/core';
import { Schedule } from '@withjoy/joykit/icons';
import { createDateFromUnformattedString } from '@shared/utils/useDateUtils';
import { AccommodationCombinedType } from '../../Accommodation.types';
import { useFeatureValue } from '@shared/core/featureFlags';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { body1ToParagraphOverride, body2ToParagraphOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';
import { pxToRem } from '@withjoy/joykit/theme';
import { Category } from '@graphql/generated';

export const AccommodationTileSchedule: React.FC<{ type: AccommodationCombinedType; cutoffDate?: string | null }> = ({ cutoffDate, type }) => {
  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');

  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';
  const { scaleFontSize } = useScaleGuestSiteFontSize();

  if (type !== AccommodationCombinedType.Roomblock) return null;

  return (
    <Flex flexDirection={'row'} columnGap={5}>
      <Flex>
        <Schedule size={48} />
      </Flex>
      <Flex flexDirection={'column'}>
        <GuestSiteTypographyOverride override={body2ToParagraphOverride} defaultFontFamily="Inter UI">
          <TextV2
            {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
            fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
            fontWeight={600}
            lineHeight={'22.5px'}
            letterSpacing={'-0.15px'}
            paddingBottom={1}
          >
            {accTrans.beforeDate({ date: createDateFromUnformattedString(cutoffDate || '').toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' }) })}
          </TextV2>
        </GuestSiteTypographyOverride>
        <GuestSiteTypographyOverride override={body1ToParagraphOverride} defaultFontFamily="Inter UI">
          <TextV2
            typographyVariant={'body1'}
            {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
            fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
            fontWeight={400}
            color={'mono12'}
          >
            {accTrans.riskFreeCancellation()}
          </TextV2>
        </GuestSiteTypographyOverride>
      </Flex>
    </Flex>
  );
};
