import React, { useCallback } from 'react';
import { VipFragment, useGetGuestsiteVipsQuery } from '@graphql/generated';
import { getInitialsFromName } from '@shared/utils/getInitials';
import { ColumnsV2, Box, ColumnV2Props, SpacingStack, BoxProps } from '@withjoy/joykit';
import { ProfileDialog, useProfileDialog } from '@shared/components/ProfileDialog';
import { useWeddingPartyListTranslations, WeddingPartyListTranslations } from './WeddingPartyList.i18n';
import { StyledItemInitials, StyledItemAvatar, StyledWedginPartyText } from './WeddingPartyList.styles';
import { withLazyPropOnIntersection } from '@shared/utils/hooks/useLazyLoadImage';
import { addRendition } from '@shared/utils/photoRendition';
import { TelemetryProvider, useWeddingPartyListTelemetry } from './WeddingPartyList.telemetry';
import { useLayout } from '../../layouts/LayoutProvider';
import { isInIframe } from '@shared/utils/isInIframe';
import { useEventUserRole } from '@shared/components/AuthProvider';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { body2ToSubHeadingOverride, label6ToParagraphOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';

export interface WeddingPartyListProps extends BoxProps {
  children?: never;
  graphicAccent: Maybe<string> | undefined;
  columnCount?: ColumnV2Props['span'];
  eventId: string;
}

export interface WeddingPartyContentProps extends BoxProps {
  children?: never;
  graphicAccent: Maybe<string> | undefined;
  columnCount?: ColumnV2Props['span'];
  eventId: string;
  list: VipFragment[];
}

const LazyStyledItemAvatar = withLazyPropOnIntersection(StyledItemAvatar, 'backgroundImage', 200);
const WeddingPartyListItem: React.FC<{
  columnCount?: ColumnV2Props['span'];
  item: VipFragment;
  index: number;
  translations: WeddingPartyListTranslations;
  onOpenProfileDialog: (index: number) => void;
}> = React.memo(({ columnCount, index, item, translations, onOpenProfileDialog }) => {
  const { photo, alias } = item;
  const { ariaLabel, title } = translations;
  const handleClick = () => onOpenProfileDialog(index);

  return (
    <>
      <ColumnsV2.Column span={columnCount} marginTop={8}>
        <LazyStyledItemAvatar
          role="img"
          aria-label={ariaLabel}
          data-testid="vip-photo"
          backgroundImage={{
            onscreen: photo
              ? `url("${addRendition({
                  url: photo.url,
                  renditionSize: 'small'
                })}")`
              : undefined
          }}
          onClick={handleClick}
        >
          {!photo && alias && <StyledItemInitials data-testid="vip-initials">{getInitialsFromName(alias)}</StyledItemInitials>}
        </LazyStyledItemAvatar>
        <SpacingStack data-testid="vip-index-item" cursor={'pointer'} textAlign="center" marginTop={5} onClick={handleClick}>
          {alias && (
            <GuestSiteTypographyOverride override={body2ToSubHeadingOverride}>
              <StyledWedginPartyText typographyVariant="body2" data-testid="vip-alias">
                {alias}
              </StyledWedginPartyText>
            </GuestSiteTypographyOverride>
          )}
          {title && (
            <GuestSiteTypographyOverride override={label6ToParagraphOverride}>
              <StyledWedginPartyText typographyVariant="label6" data-testid={'vip-title'}>
                {title}
              </StyledWedginPartyText>
            </GuestSiteTypographyOverride>
          )}
        </SpacingStack>
      </ColumnsV2.Column>
    </>
  );
});

const WeddingPartyContent = React.forwardRef<HTMLDivElement, WeddingPartyContentProps>(({ graphicAccent, list, columnCount = [6, 4], ...restProps }, ref) => {
  const { getItemTranslations } = useWeddingPartyListTranslations();
  const { activeIndex, isOpen, openProfileDialog, closeProfileDialog } = useProfileDialog();
  const telemetry = useWeddingPartyListTelemetry();

  const handleOpenedDialog = useCallback(
    index => {
      openProfileDialog(index);
      telemetry.VIPDetailsOpened(index);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleCloseDialog = useCallback(() => {
    closeProfileDialog();
    telemetry.VIPDetailsClosed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box ref={ref} {...restProps}>
      <ColumnsV2>
        {list.map((item, index) => {
          const translations = getItemTranslations(item);
          return (
            <WeddingPartyListItem
              key={item.id}
              item={item}
              index={index}
              columnCount={columnCount}
              translations={translations}
              onOpenProfileDialog={() => handleOpenedDialog(index)}
            />
          );
        })}
      </ColumnsV2>
      <ProfileDialog activeIndex={activeIndex} graphicAccent={graphicAccent} list={list} isOpen={isOpen} onClose={handleCloseDialog} />
    </Box>
  );
});

const WeddingPartyList = React.forwardRef<HTMLDivElement, WeddingPartyListProps>(({ graphicAccent, columnCount = [6, 4], ...restProps }, ref) => {
  const { layout } = useLayout();
  const { eventId } = restProps;
  const isPreviewing = isInIframe();
  const { isAdmin } = useEventUserRole();

  const { data, loading } = useGetGuestsiteVipsQuery({
    variables: { eventId: eventId, isAdminDashboard: isPreviewing && isAdmin },
    batchMode: 'fast',
    fetchPolicy: 'cache-and-network',
    ssr: false
  });

  if (loading) return <div style={{ height: '300px' }}></div>; // height set to prevent divs from jumping around while page loads

  const list = data?.eventById?.vips || [];
  return (
    <TelemetryProvider context={{ eventId: eventId, layout: layout }}>
      <WeddingPartyContent ref={ref} {...restProps} columnCount={columnCount} list={list} graphicAccent={graphicAccent} />
    </TelemetryProvider>
  );
});

WeddingPartyList.displayName = 'WeddingPartyList';

export { WeddingPartyList };
