import React, { useEffect, useState } from 'react';
import { useTranslation } from '@shared/core';
import { pxToRem } from '@withjoy/joykit/theme';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { SkeletonThumbnail } from '@shared/components/Skeleton';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { Flex, FabButton, Box, BoxProps, TextV2 } from '@withjoy/joykit';
import { Category, PointOnMapType, PointsOnMapFragment } from '@graphql/generated';
import { ZoomContract } from '@withjoy/joykit/icons';

import { useCustomPageTelemetry } from '../../../../Custom.telemetry';
import { RemoveScroll, mapSizeVariants } from './AccommodationsMapV2.styles';
import { AccommodationCombined } from '../Accommodations/Accommodation.types';
import { DirectionsMap } from '@shared/components/DirectionsMap';
import { button1ToButtonsOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';
import { useFeatureValue } from '@shared/core/featureFlags';

interface AccommodationsMapV2Props {
  pageTitle?: string;
  pointsOnMap: PointsOnMapFragment[];
  accommodationsCombined: AccommodationCombined[];
  showFullScreenMap: boolean;
  isBrannanLayout?: boolean;
  onSelectHotel?: (id: string) => void;
  locationBeingHovered?: string;
  borderRadius?: BoxProps['borderRadius'];
  height?: BoxProps['height'];
  hideExploreCta?: boolean;
  hideDirection?: boolean;
}

const AccommodationsMapV2: React.FC<AccommodationsMapV2Props> = ({
  pageTitle,
  pointsOnMap,
  accommodationsCombined,
  showFullScreenMap,
  isBrannanLayout,
  onSelectHotel,
  locationBeingHovered,
  borderRadius,
  height,
  hideExploreCta,
  hideDirection
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));

  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');
  const telemetry = useCustomPageTelemetry();
  const { scaleFontSize } = useScaleGuestSiteFontSize();
  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';

  const destination = pointsOnMap.find(pointOnMap => pointOnMap.type === PointOnMapType.venue);

  const originList = accommodationsCombined.map(accommodation => ({
    index: accommodation.index,
    id: accommodation.id,
    lat: accommodation.latitude ?? 0,
    lng: accommodation.longitude ?? 0
  }));

  const buttonSize = isFullScreen ? 48 : 40;
  const mapSize = isFullScreen ? mapSizeVariants(isBrannanLayout).fullscreen : mapSizeVariants(isBrannanLayout).normalscreen;

  const toggleFullScreen = useEventCallback((fullscreen: boolean) => {
    setIsFullScreen(fullscreen);
  });

  useEffect(() => {
    setIsFullScreen(showFullScreenMap);
  }, [showFullScreenMap]);

  const handleMapExpanded = () => {
    telemetry.mapExpanded('accommodations');
  };

  const handleMapContracted = () => {
    telemetry.mapContracted('accommodations');
  };

  const onMapClick = useEventCallback(() => {
    telemetry.accommodationsMapClicked('accommodations');
  });

  return (
    <>
      {isFullScreen && <RemoveScroll />}
      <Flex width={'100%'} height={'100%'} margin={isBrannanLayout && isMobile ? 'auto' : 'inherit'}>
        <Flex
          {...mapSize}
          {...(height && { height })}
          borderRadius={isFullScreen ? 0 : borderRadius ? borderRadius : isBrannanLayout ? pxToRem(24) : pxToRem(12)}
          overflow="hidden"
        >
          <DirectionsMap
            destination={{ lat: destination?.latitude ?? 0, lng: destination?.longitude ?? 0 }}
            originList={originList}
            locationBeingHovered={locationBeingHovered}
            gestureHandling={isFullScreen ? 'greedy' : 'cooperative'}
            onMapClick={onMapClick}
            onOriginClick={onSelectHotel}
            hideDirection={hideDirection}
          >
            {isFullScreen && pageTitle && (
              <Box padding={6} width="100%" textAlign="center" position="absolute" backgroundColor="white">
                <TextV2
                  wordBreak="break-word"
                  marginX={'0.24rem'}
                  typographyVariant="display4"
                  color="mono12"
                  fontSize={{ _: pxToRem(40), sm2: pxToRem(48) }}
                  fontWeight={{ _: 52, sm2: 62.4 }}
                >
                  {pageTitle}
                </TextV2>
              </Box>
            )}
            {isFullScreen && (
              <FabButton
                right={5}
                top={isFullScreen ? 124 : 5}
                padding={0}
                position="absolute"
                variant="ghostShadow"
                minWidth={pxToRem(buttonSize)}
                maxWidth={pxToRem(buttonSize)}
                width={pxToRem(buttonSize)}
                height={pxToRem(buttonSize)}
                onClick={() => {
                  toggleFullScreen(!isFullScreen);
                  isFullScreen ? handleMapContracted() : handleMapExpanded();
                }}
              >
                <ZoomContract size="md" />
              </FabButton>
            )}
            {!isFullScreen && !isMobile && !hideExploreCta && (
              <GuestSiteTypographyOverride override={button1ToButtonsOverride} defaultFontFamily="Inter UI">
                <FabButton
                  color="mono14"
                  right="50%"
                  transform="translate(50%, 0px)"
                  bottom={7}
                  paddingY={0}
                  paddingX={5}
                  position="absolute"
                  variant="ghostShadow"
                  typographyVariant="button1"
                  fontSize={scaleFontSize(pxToRem(16), Category.BUTTONS)}
                  {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                  fontWeight={600}
                  height={pxToRem(buttonSize)}
                  onClick={() => {
                    toggleFullScreen(!isFullScreen);
                    isFullScreen ? handleMapContracted() : handleMapExpanded();
                  }}
                >
                  {accTrans.exploreOnMap()}
                </FabButton>
              </GuestSiteTypographyOverride>
            )}
          </DirectionsMap>
        </Flex>
      </Flex>
    </>
  );
};

AccommodationsMapV2.displayName = 'AccommodationsMapV2';

const AccommodationsMapV2Skeleton: React.FC<{ isBrannanLayout?: boolean }> = ({ isBrannanLayout }) => {
  return (
    <Flex width="100%" display={{ _: 'flex', sm2: 'flex' }} maxWidth={isBrannanLayout ? { _: '520px', sm2: '100%' } : {}}>
      <SkeletonThumbnail
        overflow="hidden"
        height={isBrannanLayout ? { _: pxToRem(284), sm2: pxToRem(503) } : { _: pxToRem(273), sm2: pxToRem(396), sm4: pxToRem(329) }}
        marginBottom={32}
        overrides={{
          PlaceholderRoot: {
            props: {
              width: '100%',
              paddingTop: '75%',
              backgroundColor: 'white',
              borderRadius: '12px !important'
            }
          }
        }}
      />
    </Flex>
  );
};

export { AccommodationsMapV2, AccommodationsMapV2Skeleton };
