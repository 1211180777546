import React from 'react';
import { GuestTravelMapComponentFragment } from '@graphql/generated';
import { useFeatureValue } from '@shared/core/featureFlags';
import { TravelMap as TravelMapV1 } from './TravelMap';
import { TravelMapV2 } from './TravelMapV2';

interface TravelMapProps {
  pageId: string;
  eventId: string;
  pageSlug: string;
  eventHandle: string;
  pageTitle?: string;
  subTitle?: string | null;
  isBrannanLayout?: boolean;
  travelMapComponentData: GuestTravelMapComponentFragment;
}

export const TravelMap: React.FC<TravelMapProps> = props => {
  const accommodationsGuestV2ExperimentEnabled = useFeatureValue('accommodationsGuestV2Experiment').value === 'treatment';
  return accommodationsGuestV2ExperimentEnabled ? <TravelMapV2 {...props} /> : <TravelMapV1 {...props} />;
};
