import React from 'react';
import { Box, ButtonV2, CloseButton, Flex, TextV2 } from '@withjoy/joykit';
import { ResponsiveDialog } from '@shared/components/ResponsiveDialog/ResponsiveDialog';
import { closeButtonOverrides, drawerOverrides } from '@shared/components/ResponsiveDialog/ResponsiveDialog.styles';
import { AccommodationCombined, AccommodationCombinedType } from '../Accommodations/Accommodation.types';
import { DEFAULT_HOTEL_IMAGE } from '../../TravelMap.constants';
import { pxToRem } from '@withjoy/joykit/theme';
import { Category, PointsOnMapFragment } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { distanceInMiles } from '@shared/utils/distanceInMiles';
import { useAccommodationsCta } from '../../hooks/useAccommodationsCta';
import { AccommodationsMapV2 } from '../AccommodationsMapV2/AccommodationsMapV2';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { useFeatureValue } from '@shared/core/featureFlags';
import {
  body1ToParagraphOverride,
  body2ToSubHeadingOverride,
  button1ToButtonsOverride,
  button2ToButtonsOverride,
  hed3ToSubHeadingOverride
} from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

export interface AccommodationsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  setSafeLink: (safeLink?: string) => void;
  accommodation: AccommodationCombined;
  showDefaultData: boolean;
  shouldShowMap?: boolean;
  pointsOnMap: PointsOnMapFragment[];
  pageTitle?: string;
  locationInfo?: PointsOnMapFragment;
  eventHandle: string;
  eventPhoto: string;
  hotelTileGuestCountEnabled?: boolean;
}

export const AccommodationsDialog: React.FC<AccommodationsDialogProps> = props => {
  const {
    isOpen,
    onClose,
    setSafeLink,
    accommodation,
    showDefaultData,
    shouldShowMap,
    pointsOnMap,
    pageTitle,
    locationInfo,
    eventHandle,
    eventPhoto,
    hotelTileGuestCountEnabled
  } = props;
  const {
    photo,
    displayName,
    description,
    latitude,
    longitude,
    type,
    pricePerNight,
    isFavorite,
    strikeoutPricePerNight,
    url,
    id,
    zhId,
    starRating,
    interestedGuestCount
  } = accommodation;

  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));
  const haveDistanceToVenue = locationInfo?.latitude && locationInfo?.longitude && latitude && longitude;
  const milesToVenue = distanceInMiles(locationInfo?.latitude || 0, locationInfo?.longitude || 0, latitude || 0, longitude || 0).toFixed(1);
  const isRoomblock = type === AccommodationCombinedType.Roomblock;

  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');
  const accommodationsPlacesV2Enabled = useFeatureValue('accommodationsPlacesV2Enabled').value === 'on';
  const { scaleFontSize } = useScaleGuestSiteFontSize();
  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';

  const setRedirectLink = useEventCallback((redirectLink: string) => {
    setSafeLink(redirectLink);
    onClose();
  });

  const { ctaTitle, handleOnSelectAccommodation } = useAccommodationsCta({
    eventHandle,
    displayName,
    type,
    pricePerNight,
    isFavorite,
    strikeoutPricePerNight,
    zhId,
    url,
    id,
    interestedGuestCount,
    clickedFrom: 'dialog',
    hotelTileGuestCountEnabled,
    setRedirectLink
  });

  return (
    <ResponsiveDialog
      isOpen={isOpen}
      onClose={onClose}
      hideDivider={true}
      removeBodyPadding={true}
      hasCloseButton={false}
      drawerOverrides={{
        ...drawerOverrides,
        Content: {
          props: {
            ...drawerOverrides.Content?.props,
            boxShadow: '0px -7px 16px -8px rgba(0, 0, 0, 0.3), 0px -10px 27px -5px rgba(44, 41, 37, 0.25)'
          }
        },
        Body: { props: { ...drawerOverrides.Body?.props, paddingY: 0, paddingX: 0 } }
      }}
      dialogOverrides={{
        Content: { props: { width: '100%', maxWidth: 620 } },
        Body: { props: { paddingX: 0, paddingY: 0 } }
      }}
    >
      <Box position="relative" height="100%" width="100%">
        <CloseButton
          overrides={{
            Root: {
              props: {
                ...closeButtonOverrides.Root?.props,
                position: 'absolute',
                top: pxToRem(16),
                right: pxToRem(16),
                zIndex: 1,
                width: pxToRem(32),
                height: pxToRem(32),
                minWidth: pxToRem(32),
                background: 'rgba(255, 255, 255, 0.20)',
                backdropFilter: 'blur(8px)',
                border: 'none'
              }
            },
            Icon: { props: { ...closeButtonOverrides.Icon?.props } }
          }}
          onClick={onClose}
          aria-label={'close dialog'}
        />
        <Flex flexDirection="column" gap={description ? 6 : 7}>
          <Box
            width="100%"
            height={pxToRem(206)}
            borderTopLeftRadius={{ _: 0, sm2: pxToRem(12) }}
            borderTopRightRadius={{ _: 0, sm2: pxToRem(12) }}
            objectFit={'cover'}
            backgroundSize={'cover'}
            backgroundPosition={`center ${showDefaultData ? 'bottom' : 'center'}`}
            backgroundRepeat={'no-repeat'}
            backgroundImage={`url(${photo?.url || DEFAULT_HOTEL_IMAGE})`}
            display="flex"
            position={'relative'}
          />
          <Flex flexDirection="column" gap={7} alignItems="center">
            {description && (
              <Flex flexDirection="column" gap={3} alignItems="center" maxWidth={{ _: '100%', sm2: pxToRem(356) }} paddingX={{ _: 5, sm2: 0 }}>
                <GuestSiteTypographyOverride override={body2ToSubHeadingOverride} defaultFontFamily="Inter UI">
                  <TextV2
                    {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                    fontSize={scaleFontSize(pxToRem(18), Category.SUB_HEADING)}
                    fontWeight={500}
                    color="mono10"
                    textAlign="center"
                  >
                    {description}
                  </TextV2>
                </GuestSiteTypographyOverride>
                {eventPhoto !== '' && (
                  <Box
                    width={pxToRem(32)}
                    height={pxToRem(32)}
                    border="1px solid rgba(0, 0, 0, 0.05)"
                    borderRadius="50%"
                    objectFit={'cover'}
                    backgroundSize={'cover'}
                    backgroundPosition={`center ${showDefaultData ? 'bottom' : 'center'}`}
                    background={`url(${eventPhoto}) lightgray -7px -20.25px / 142.187% 213.281% no-repeat`}
                    display="flex"
                    position={'relative'}
                  />
                )}
              </Flex>
            )}
            <Flex width="100%" display={{ _: 'flex', sm2: 'grid' }} flexDirection="column" gridTemplateColumns="1fr 2fr" gap={6} paddingX={{ _: 5, sm2: 7 }} paddingBottom={7}>
              <Flex width="100%">
                {shouldShowMap && (
                  <AccommodationsMapV2
                    showFullScreenMap={false}
                    pointsOnMap={pointsOnMap}
                    accommodationsCombined={[accommodation]}
                    pageTitle={pageTitle}
                    isBrannanLayout={true}
                    borderRadius={pxToRem(8)}
                    height={{ _: pxToRem(160), sm2: undefined }}
                    hideExploreCta
                  />
                )}
              </Flex>
              <Flex flexDirection="column" gap={6}>
                <Flex flexDirection="column" gap={2}>
                  <Flex justifyContent="space-between">
                    <GuestSiteTypographyOverride override={hed3ToSubHeadingOverride} defaultFontFamily="Inter UI">
                      <TextV2
                        typographyVariant="hed3"
                        {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                        fontSize={scaleFontSize(pxToRem(20), Category.SUB_HEADING)}
                        fontWeight={600}
                      >
                        {displayName}
                      </TextV2>
                    </GuestSiteTypographyOverride>
                    {starRating && (
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        width={pxToRem(36)}
                        height={pxToRem(25)}
                        borderRadius={pxToRem(4)}
                        backgroundColor="accentBlue4"
                        paddingX={pxToRem(6)}
                      >
                        <GuestSiteTypographyOverride override={button1ToButtonsOverride}>
                          <TextV2
                            typographyVariant="button1"
                            {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                            fontSize={scaleFontSize(pxToRem(15), Category.BUTTONS)}
                            fontWeight={600}
                            color="white"
                          >
                            {starRating}
                          </TextV2>
                        </GuestSiteTypographyOverride>
                      </Flex>
                    )}
                  </Flex>
                  {haveDistanceToVenue && (
                    <GuestSiteTypographyOverride override={body1ToParagraphOverride} defaultFontFamily="Inter UI">
                      <TextV2 typographyVariant={'body1'} {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })} color="mono10" textAlign="left">
                        {accTrans.milesAway({ miles: milesToVenue })}
                      </TextV2>
                    </GuestSiteTypographyOverride>
                  )}
                </Flex>
                <Flex flexDirection="column" gap={4}>
                  {(pricePerNight || !isMobile) && (
                    <>
                      {pricePerNight ? (
                        <Flex gap={3} justifyContent="space-between" alignItems="baseline">
                          <Flex alignItems="baseline" gap={2}>
                            <GuestSiteTypographyOverride override={body1ToParagraphOverride} defaultFontFamily="Inter UI">
                              <TextV2
                                typographyVariant={'body1'}
                                {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                                fontSize={scaleFontSize(pxToRem(24), Category.PARAGRAPH)}
                                fontWeight={600}
                                color="mono14"
                                textAlign="left"
                              >
                                ${pricePerNight}
                              </TextV2>
                            </GuestSiteTypographyOverride>
                            <GuestSiteTypographyOverride override={body1ToParagraphOverride} defaultFontFamily="Inter UI">
                              <TextV2
                                typographyVariant={'body1'}
                                {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                                fontSize={scaleFontSize(pxToRem(14), Category.PARAGRAPH)}
                                fontWeight={500}
                                color="mono14"
                                textAlign="left"
                              >
                                {accTrans.night()}
                              </TextV2>
                            </GuestSiteTypographyOverride>
                          </Flex>
                          {isRoomblock && (
                            <GuestSiteTypographyOverride override={body1ToParagraphOverride} defaultFontFamily="Inter UI">
                              <TextV2
                                typographyVariant={'body1'}
                                {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                                fontSize={scaleFontSize(pxToRem(14), Category.PARAGRAPH)}
                                fontWeight={500}
                                color="accentBlue4"
                                textAlign="left"
                              >
                                {accTrans.exclusiveBlockRate()}
                              </TextV2>
                            </GuestSiteTypographyOverride>
                          )}
                        </Flex>
                      ) : (
                        <Flex height={pxToRem(24)}></Flex>
                      )}
                    </>
                  )}
                  {type === AccommodationCombinedType.Roomblock || url || accommodationsPlacesV2Enabled ? (
                    <GuestSiteTypographyOverride override={button2ToButtonsOverride} defaultFontFamily="Inter UI">
                      <ButtonV2
                        width="100%"
                        backgroundColor="black"
                        intent="neutral"
                        shape="rounded"
                        fontWeight={600}
                        fontSize={scaleFontSize(pxToRem(16), Category.BUTTONS)}
                        {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                        onClick={handleOnSelectAccommodation}
                      >
                        {ctaTitle}
                      </ButtonV2>
                    </GuestSiteTypographyOverride>
                  ) : null}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </ResponsiveDialog>
  );
};
