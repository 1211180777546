import React, { useMemo } from 'react';
import { PrintJobDetails } from '@apps/card/components/PrintJobDetails';
import { Flex, TextV2 } from '@withjoy/joykit';
import { StationeryPrintOrderByOrderNumberQuery, useStationeryPrintOrderByOrderNumberQuery } from '@graphql/generated';
import { Layout } from '@apps/card/components/Layout';
import { SkeletonGroup } from '@shared/components/Skeleton';
import { pxToRem } from '@withjoy/joykit/theme';
import { PrimaryNav } from '../Dashboard/components/PrimaryNav';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { ScrollToTop } from '@apps/common/components/ScrollToTop/ScrollToTop';
import { PrintJobDetailsTelemetryProxyProvider } from '../Dashboard/components/PrintJobDetailsTelemetryProxyProvider';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { DigitalJobDetails } from '@apps/card/components/PrintJobDetails/DigitalJobDetails';
import { PremiumDigitalJobDetailsTelemetryProxyProvider } from '@apps/card/components/PremiumDigitalJobDetailsTelemetryProxyProvider/PremiumDigitalJobDetailsTelemetryProxyProvider';
import { deriveOrderFormat } from '@apps/card/Card.utils';

interface OrderDetailsProps {
  data?: StationeryPrintOrderByOrderNumberQuery;
  disableTelemetry?: boolean;
}

export const OrderDetails = ({ data, disableTelemetry }: OrderDetailsProps) => {
  const component = useMemo(() => {
    if (!data?.stationeryPrintOrderByOrderNumber) {
      return null;
    }

    const format = deriveOrderFormat(data.stationeryPrintOrderByOrderNumber);

    if (format === 'digital') {
      return <DigitalJobDetails variant="detailed" order={data.stationeryPrintOrderByOrderNumber} />;
    }

    if (format === 'print') {
      return <PrintJobDetails variant="detailed" order={data.stationeryPrintOrderByOrderNumber} />;
    }

    return null;
  }, [data]);

  const TelemetryProxyProvider =
    data?.stationeryPrintOrderByOrderNumber?.cardDraft?.format === 'print' ? PrintJobDetailsTelemetryProxyProvider : PremiumDigitalJobDetailsTelemetryProxyProvider;

  return (
    <SkeletonGroup maxWidth="100%" isReady={!!data?.stationeryPrintOrderByOrderNumber}>
      {data?.stationeryPrintOrderByOrderNumber && (
        <Flex flexDirection="column" rowGap={{ _: 8, md: 10 }} width={{ _: '100%', md2: 1080 }} maxWidth="100%">
          <TextV2 typographyVariant={{ _: 'HedMobile32', md: 'Hed56' }} paddingX={{ _: 6, sm2: 8, md: 0 }}>
            Order Summary
          </TextV2>
          {disableTelemetry ? (
            component
          ) : (
            <TelemetryProxyProvider viewVariant="detailed" order={data.stationeryPrintOrderByOrderNumber}>
              {component}
            </TelemetryProxyProvider>
          )}
        </Flex>
      )}
    </SkeletonGroup>
  );
};

interface OrderDetailsRouteProps {
  printOrderNumber: string;
}

export const OrderDetailsRoute = (props: OrderDetailsRouteProps) => {
  const { printOrderNumber } = props;

  const { getShopPath } = useCardsRouterContext();
  const isMobileOrTablet = useIsMobileOrTablet();

  useCardTelemetry({ page: 'orderDetailsRoute' });

  const { data } = useStationeryPrintOrderByOrderNumberQuery({
    variables: {
      orderNumber: printOrderNumber
    },
    batchMode: 'fast'
  });

  return (
    <Layout.Grid>
      <ScrollToTop />
      <Layout.Main>
        <Layout.Header alignment="flex-start" title="Paper Cards" titleRedirectPath={getShopPath('wedding')}>
          {!isMobileOrTablet && <PrimaryNav />}
        </Layout.Header>
        {isMobileOrTablet && <PrimaryNav />}
        <Flex justifyContent="center" paddingTop={10} paddingBottom={pxToRem(97)}>
          <OrderDetails data={data} />
        </Flex>
      </Layout.Main>
    </Layout.Grid>
  );
};
