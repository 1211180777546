import React, { useMemo } from 'react';
import { Flex, TextV2 } from '@withjoy/joykit';
import { StationeryPrintOrderDetailsFragment } from '@graphql/generated';
import ReceiptBlock from '@apps/card/components/Blocks/ReceiptBlock';
import { useTranslation } from '@shared/core';
import { PRODUCT_OFFERINGS } from '@apps/card/routes/Dashboard/components/ConciergeServiceSection/ConciergeServiceSection.config';
import { getConciergeCategoryFromDesignSetName } from '@apps/card/routes/Concierge/Concierge.utils';
import useCalculateConciergeOrderPrice from '@apps/card/routes/Concierge/hooks/useCalculateConciergeOrderPrice';
import { formatter } from '@apps/card/routes/Concierge/config';
import useValidatePromoCode from '@apps/card/routes/Concierge/components/Review/components/PromoCodeInput/PromoCodeValidator';
import { ProductInfo } from '@apps/card/routes/CardCustomizer/usePromotionConfig';

export const ConciergePaymentSummary = ({ order }: { order: StationeryPrintOrderDetailsFragment }) => {
  const { t } = useTranslation('stationery');
  const trans = t('cardCustomizer', 'priceSummary');

  const category = useMemo(() => {
    return getConciergeCategoryFromDesignSetName(order.designSetName!);
  }, [order]);

  const categoryName = t('stationeryTemplateCategoryToTile', category)();
  const { taxInMinorUnits } = order;
  const { discount } = order;
  const promoCode = discount?.code;
  const product: ProductInfo = {
    occasion: 'wedding',
    category,
    vendor: 'paperlust'
  };
  const { validatePromoCode } = useValidatePromoCode(product);

  const validatedCode = validatePromoCode({
    code: promoCode ?? '',
    applied: false,
    error: undefined
  });

  const orderPrice = useCalculateConciergeOrderPrice({
    designSetName: order.designSetName || '',
    quantity: order.quantity,
    category,
    customizations: {
      magnet: !!order.magnetCard
    },
    promoCode: validatedCode,
    product
  });

  const taxString = formatter.formatCurrency((taxInMinorUnits || 0) / 100, { form: 'auto' });
  const total = formatter.formatCurrency((orderPrice.totalPrice.minorUnits + (taxInMinorUnits || 0)) / 100, { form: 'auto' });

  const summary: Record<'card' | 'shipping' | 'tax', Array<[label: string, value: string | undefined]>> & {
    total: string;
  } = {
    card: orderPrice.lineItems,
    shipping: [['Standard DHL Shipping', 'FREE']],
    tax: [['Sales Tax', taxString]],
    total: total
  };

  const suiteName = PRODUCT_OFFERINGS[category].find(({ identifier }) => identifier === order.designSetName)?.longName;

  return (
    <Flex
      width={{ _: '100%', md: '40%' }}
      alignSelf={{ _: 'center', md: 'flex-end' }}
      paddingX={{ _: 6, md: 0 }}
      flexDirection={{ _: 'column', md: 'row' }}
      rowGap={10}
      columnGap={140}
    >
      <Flex flexDirection="column" flex={1}>
        <Flex flexDirection="column" rowGap={6}>
          <TextV2 typographyVariant="hed3">{trans.orderSummaryLabel()}</TextV2>
          <ReceiptBlock title={categoryName} subTitle={suiteName} lines={summary.card} />
          <ReceiptBlock title="Shipping Method" lines={summary.shipping} />
          {!!taxInMinorUnits && <ReceiptBlock title="Taxes" lines={summary.tax} />}
          <Flex flexDirection="column" paddingTop={3} borderTop="1px solid #EBEBEB">
            <Flex justifyContent="space-between" alignItems="center">
              <TextV2 typographyVariant="hed3" fontSize="15px">
                {trans.totalLabel()}
              </TextV2>
              <TextV2 typographyVariant="hed3">{summary.total}</TextV2>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
