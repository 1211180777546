import React, { useContext } from 'react';
import { ButtonV2, useDisclosure } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { Search } from '@withjoy/joykit/icons';
import { BookingAssistant } from '@apps/guest/packages/layout-engine/components/BookingAssistant';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useBookingAssistantInfo } from './hooks/useBookingAssistantInfo';
import { AccommodationCombined } from '../Accommodations/Accommodation.types';
import { Category, PointsOnMapFragment } from '@graphql/generated';
import { AnalyticsContext, useTranslation } from '@shared/core';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { useFeatureValue } from '@shared/core/featureFlags';
import { button2ToButtonsOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';

interface BookingAssistantCtaProps {
  pageSlug: string;
  eventTimezone: Maybe<string>;
  venue: Maybe<PointsOnMapFragment>;
  accommodations: Maybe<AccommodationCombined[]>;
}

export const BookingAssistantCta: React.FC<BookingAssistantCtaProps> = ({ pageSlug, eventTimezone: timezone, venue, accommodations }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const analytics = useContext(AnalyticsContext);

  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';
  const { scaleFontSize } = useScaleGuestSiteFontSize();

  const { t2 } = useTranslation('guestSiteCustom');
  const bookingAssistantTrans = t2('bookingAssistant');

  const { ...bookingAssistantInfo } = useBookingAssistantInfo({ venue, accommodations });

  const handleOnClick = useEventCallback(() => {
    analytics.track({
      action: 'BookingAssistantClicked',
      actionType: 'click',
      category: 'wedding',
      pagePrefix: 'guest',
      page: pageSlug
    });
    onOpen();
  });

  const onProviderClicked = useEventCallback((checkIn: Maybe<string>, checkOut: Maybe<string>, guests: Maybe<string>, url: Maybe<string>, provider: Maybe<string>) => {
    analytics.track({
      action: 'BookingAssistantSearchProviderClicked',
      actionType: 'click',
      category: 'wedding',
      pagePrefix: 'guest',
      page: pageSlug,
      label: provider || '',
      extraInfo: {
        url,
        checkIn,
        checkOut,
        guests,
        provider
      }
    });
    analytics.track({
      action: 'LinkInteracted',
      category: 'linkify',
      label: 'accommodations',
      extraInfo: {
        url,
        checkIn,
        checkOut,
        guests,
        provider,
        actionType: 'click',
        source: 'accommodations'
      }
    });
  });

  return (
    <>
      <GuestSiteTypographyOverride override={button2ToButtonsOverride} defaultFontFamily="Inter UI">
        <ButtonV2
          width={pxToRem(244)}
          startIcon={<Search size="md" />}
          typographyVariant="button2"
          intent="neutral"
          shape="rounded"
          variant="outline"
          fontWeight={600}
          fontSize={scaleFontSize(pxToRem(16), Category.BUTTONS)}
          {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
          onClick={handleOnClick}
        >
          {bookingAssistantTrans.cta}
        </ButtonV2>
      </GuestSiteTypographyOverride>
      <BookingAssistant {...bookingAssistantInfo} timezone={timezone} onProviderClicked={onProviderClicked} isOpen={isOpen} onClose={onClose} />
    </>
  );
};
