import { capitalize, cloneDeep, omit } from 'lodash-es';
import { CardAddress, CardDieCut, ThemeJson } from './routes/CardCustomizer/CardCustomizer.types';
import { StationeryDraftFormat, StationeryPrintOrderDetailsFragment } from '@graphql/generated';

export const isValidCardAddress = (address?: CardAddress) => {
  if (!address) {
    return false;
  }

  return Object.values(omit(address, ['address2'])).every(Boolean);
};

export const templateToTitle = ({ name, themeId }: { name?: string | null | undefined; themeId: string }) => {
  if (name) return name;
  return themeId
    .replace(/_7x5$/, '')
    .replace(/_qr$/, '')
    .replace(/_thank_you$/, '')
    .split('_')
    .map(capitalize)
    .join(' ')
    .replace(/ Iv$/, ' IV')
    .replace(/ Iii$/, ' III')
    .replace(/ Ii$/, ' II')
    .replace(/ A /, ' a ')
    .replace(/ And /, ' & ')
    .replace(/ In /, ' in ')
    .replace(/ Of /, ' of ')
    .replace(/Lets /, "Let's ")
    .replace(/ Qr /, ' QR ')
    .replace(/ Rsvp/, ' RSVP');
};

export const cardDieCutToShapeConfig = (shape: CardDieCut) => {
  if (shape === CardDieCut.rounded) {
    const radius = `${54 / 16}em`;
    return {
      borderRadius: `${radius} ${radius} ${radius} ${radius}`,
      clipPath: `inset(0 0 round ${radius} ${radius} ${radius} ${radius})`
    };
  }

  if (shape === CardDieCut.arch) {
    const radius = '11.25em';
    return {
      borderRadius: `${radius} ${radius} 0 0`,
      clipPath: `inset(0 0 round ${radius} ${radius} 0 0)`
    };
  }

  return {
    borderRadius: '0',
    clipPath: 'inset(0 0 round 0 0 0 0)'
  };
};

export function clamp(min: number, max: number, n: number): number {
  return Math.max(min, Math.min(max, n));
}

export function formatBytes(bytes: number) {
  if (bytes < 1000) return `${Math.round(bytes)} Bytes`;

  const kilobytes = bytes / 1000;
  if (kilobytes < 1000) return `${Math.round(kilobytes)} KB`;

  const megabytes = kilobytes / 1000;
  return `${Math.round(megabytes)} MB`;
}

export function adaptDigitalCardJsonToPaper(cardJSON: ThemeJson) {
  const newJson = cloneDeep(cardJSON);
  newJson.digitalCustomizations = {};

  return newJson;
}

export function deriveOrderFormat(order: StationeryPrintOrderDetailsFragment): StationeryDraftFormat | null {
  // If the order has a cardDraft, use the format from there
  // Else, if the order has a designSetName, it's a print concierge order
  return (
    order.cardDraft?.format ??
    // Concierge orders don't have an associated `cardDraft` but they do have `designSetName`
    (!!order.designSetName ? StationeryDraftFormat.print : null)
  );
}
